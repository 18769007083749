import React, { useContext, useState } from 'react';
import { SafeAreaView , View , Text, Pressable, FlatList, TextInput } from 'react-native';
import { Theme } from '../theme/Theme';
import {WinWorkGoldContext} from '../context/WinWorkGoldContext';
import PageHeader from '../components/PageHeader';
import Icon from 'react-native-vector-icons/Ionicons';

import CartListItem from '../components/CartListItem';
import ModalCentered from '../components/Modal';
import SearchBar from '../components/SearchBar';
import ProdottoListItem from '../components/ProdottoListItem';
import ClienteListItem from '../components/ClienteListItem';
import Alert from '../components/Alert';
import CurFormatter from '../components/CurFormatter';

import Pagination from '../components/Pagination';
import BarReader from '../components/BarReader';
import AsyncStorage from '@react-native-async-storage/async-storage';

function PickingCart({navigation}) {

    const context = useContext(WinWorkGoldContext);
    
    const [modalEditProductShow,setModalEditProductShow] = useState(false);
    const [selectedProduct,setSelectedProduct] = useState({qta: 0});
    
    const [cartTotal,setCartTotal] = useState(context.picking.cart.get.reduce((partialSum, item) => partialSum + item.valore, 0));

    const cartTotalFormatted = CurFormatter(cartTotal);

    const [modalConfirmShow,setModalConfirmShow] = useState(false);
    const [confirmType,setConfirmType] = useState('changeClient');

    const [alertCartSuccessShow,setAlertCartSuccessShow] = useState(false);

    const modals = {
        'product' : setModalEditProductShow,
        'confirm' : setModalConfirmShow
    }

    const confirmInfo = {
        'emptyCart' : { 'text' : 'Vuoi davvero svuotare il carrello?', 'action': () => { emptyCart() } },
        'deleteProduct' : { 'text' : 'Vuoi davvero rimuovere il prodotto ' + selectedProduct.title + ' ' + selectedProduct.desc + ' dal carrello?', 'action': () => { removeFromCart(selectedProduct) } }
    }

    const openModal = (key) => {
        modals[key](true);
    };
    const closeModal = (key) => {
        modals[key](false);
    };

    const emptyCartPrepare = () => {
        setConfirmType('emptyCart');
        openModal('confirm');
    }

    const emptyCart = () => {
        context.picking.cart.set([]);
        setCartTotal(0);
        context.picking.cart.setCounter(0);
    }

    const removeFromCartPrepare = (pr) => {
        setSelectedProduct(pr);
        setConfirmType('deleteProduct');
        openModal('confirm');
    }

    const removeFromCart = (pr) => {
        context.picking.cart.set(
            [
                ...context.picking.cart.get.filter(cartpr => cartpr.id != pr.id)
            ]
        );
        setCartTotal(cartTotal - ((pr.priceDisc > 0 && pr.priceDisc < pr.price) ? pr.priceDisc * pr.qta : pr.price * pr.qta));
        context.picking.cart.setCounter(context.picking.cart.counter - pr.qta);
    }

    const editProduct = (pr) => {
        setSelectedProduct(pr);
        openModal('product');
    }

    const saveQta = () => {
        const productInCart = context.picking.cart.get.filter(cartpr => cartpr.id == selectedProduct.id)[0];
        let priceToAdd = productInCart.netto * selectedProduct.qta;
        context.picking.cart.set(
            [
                ...(context.picking.cart.get.filter(cartpr => cartpr.id != selectedProduct.id)),
                {
                    ...selectedProduct,
                    netto: productInCart.netto,
                    valore: priceToAdd
                }
            ]
        );

        setCartTotal(cartTotal - productInCart.valore + priceToAdd);
        context.picking.cart.setCounter(context.picking.cart.counter - productInCart.qta + selectedProduct.qta);
        closeModal('product');
    }

    const changeQta = (newQta) => {
        const newQtaInt = (newQta != '') ? parseInt(newQta) : '';

        if(newQta === '' || (newQtaInt && newQtaInt > 0)) {
            setSelectedProduct({
                ...selectedProduct,
                qta: newQtaInt
            });
        }
    }

    const sendCart = async () => {
        try {

            const elencoCart = JSON.stringify(context.picking.cart.get);

            const login = await AsyncStorage.getItem('@log_store');
            const response = await fetch(context.serverUrl + '?azione=carrello&cliente=' + context.picking.cart.client.id + '&utente=' + context.user.get + '&carrello=' + elencoCart + '&loginp=' + login);
            const json = await response.json();

            if(json.stato == 'success') {
                setAlertCartSuccessShow(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const exeConfirmAction = () => {
        closeModal('confirm');
        (confirmInfo[confirmType].action)();
    }

    return(
        <SafeAreaView style={Theme[context.webappType].styles.safeArea}>
            <View style={Theme[context.webappType].styles.view}>
                <PageHeader goBack={() => navigation.goBack()} title="Carrello di Picking" ></PageHeader>
                <View style={[Theme[context.webappType].styles.itemListRow, { alignItems: 'center', borderBottomWidth: 1, borderBottomColor: Theme[context.webappType].Colors.gray, paddingHorizontal: 15}]}>
                    <View style={{flexGrow:1, flexDirection: 'column', marginRight: 20, borderRightWidth: 1, borderRightColor: Theme[context.webappType].Colors.gray, paddingVertical: 10,paddingRight: 20}}>
                        <Text style={{fontWeight: Theme[context.webappType].Weights.bold, textTransform: 'uppercase'}}>Totale</Text>
                        <Text>{cartTotalFormatted}</Text>
                    </View>
                    <View style={{flexDirection: 'column', alignItems: 'center', marginRight: 20, borderRightWidth: 1, borderRightColor: Theme[context.webappType].Colors.gray, paddingVertical: 10,paddingRight: 20}}>
                        <Text style={{textTransform: 'uppercase'}}>Quantità</Text>
                        <Text>{context.picking.cart.counter}</Text>
                    </View>
                    <View style={{flexDirection: 'column', paddingVertical: 10}}>
                        <Text style={{textTransform: 'uppercase'}}>Valore</Text>
                        <Text>{cartTotalFormatted}</Text>
                    </View>
                </View>
                {
                    context.picking.cart.get.length > 0 ?
                    (<FlatList
                        data={context.picking.cart.get}
                        renderItem={({item}) => <CartListItem item={item} handleDelete={() => {removeFromCartPrepare(item)}} handleEdit={() => {editProduct(item)}}></CartListItem>}
                        keyExtractor={item => item.id}
                    />)
                    :
                    (<View style={{flex:1}}><Text style={Theme[context.webappType].styles.emptyItem}>Nessun prodotto</Text></View>)
                }
                <View style={[Theme[context.webappType].styles.itemListRow, { justifyContent: 'space-between', borderTopWidth: 1, borderTopColor: Theme[context.webappType].Colors.gray, paddingHorizontal: 15, paddingVertical: 10}]}>
                    <Pressable style={[Theme[context.webappType].styles.genericButton,{backgroundColor: context.picking.cart.get.length == 0 ? Theme[context.webappType].Colors.danger + '55': Theme[context.webappType].Colors.danger}]} onPress={emptyCartPrepare} disabled={context.picking.cart.get.length == 0}>
                        <Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold}]}>Svuota carrello</Text>
                    </Pressable>
                    <Pressable style={[Theme[context.webappType].styles.genericButton,{backgroundColor: (context.picking.cart.client.id == 0 || context.picking.cart.get.length == 0) ? Theme[context.webappType].Colors.success + '55' : Theme[context.webappType].Colors.success}]} onPress={sendCart} disabled={context.picking.cart.client.id == 0 || context.picking.cart.get.length == 0}>
                        <Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold}]}>Conferma carrello</Text>
                    </Pressable>
                </View>
            </View>
            <ModalCentered handleClose={() => {closeModal('product')}} title="Modifica riga carrello" modalVisible={modalEditProductShow} content=
            {
                <View style={{flex: 1,alignItems: 'center', padding: 10}}>
                    <Text style={{fontSize: Theme[context.webappType].Sizes.medium}}>{selectedProduct.title} {selectedProduct.desc}</Text>
                    <View style={{display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center', padding: 10}}>
                        <Text style={{marginRight: 5}}>qta.</Text>
                        <Pressable style={[Theme[context.webappType].styles.genericButton,{backgroundColor: Theme[context.webappType].Colors.primary, padding: 5, borderRadius: 5}]} onPress={() => {if(selectedProduct.qta > 1) {changeQta(selectedProduct.qta - 1)}}}>
                            <Icon name="remove-outline" style={{fontSize: Theme[context.webappType].Sizes.medium, color: Theme[context.webappType].Colors.white}} />
                        </Pressable>
                        <TextInput style={{padding: 8,marginHorizontal: 5,width: 100, borderRadius: 5}} value={selectedProduct.qta.toString()} onChangeText={changeQta}/>
                        <Pressable style={[Theme[context.webappType].styles.genericButton,{backgroundColor: Theme[context.webappType].Colors.primary, padding: 5, borderRadius: 5}]} onPress={() => {changeQta(selectedProduct.qta + 1)}}>
                            <Icon name="add-outline" style={{fontSize: Theme[context.webappType].Sizes.medium, color: Theme[context.webappType].Colors.white}} />
                        </Pressable>
                    </View>
                    <Pressable style={[Theme[context.webappType].styles.genericButton,{backgroundColor: Theme[context.webappType].Colors.success}]} onPress={saveQta}>
                        <Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold}]}>Salva</Text>
                    </Pressable>
                </View>
            }
            ></ModalCentered>
            <ModalCentered handleClose={() => {closeModal('confirm')}} title="Sei sicuro?" modalVisible={modalConfirmShow} content=
            {
                <View style={{flex: 1,alignItems: 'center', padding: 10}}>
                    <Text style={{fontSize: Theme[context.webappType].Sizes.medium, textAlign: 'center'}}>{confirmInfo[confirmType].text}</Text>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 10, marginTop: 10, width: '100%'}}>
                        <Pressable style={[Theme[context.webappType].styles.genericButton,{backgroundColor: Theme[context.webappType].Colors.danger}]} onPress={() => {closeModal('confirm')}}>
                            <Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold}]}>Annulla</Text>
                        </Pressable>
                        <Pressable style={[Theme[context.webappType].styles.genericButton,{backgroundColor: Theme[context.webappType].Colors.success}]} onPress={() => {exeConfirmAction()}}>
                            <Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold}]}>Continua</Text>
                        </Pressable>
                    </View>
                </View>
            }
            ></ModalCentered>
            <Alert visibleTime={1000} modalVisible={alertCartSuccessShow} handleClose={() => {setAlertCartSuccessShow(false);emptyCart();}} overlayColor={Theme[context.webappType].Colors.white + 'A'} content=
            {
                <>
                    <Icon name="checkmark-outline" style={{fontSize: Theme[context.webappType].Sizes.extraLarge, color: Theme[context.webappType].Colors.success}} />
                    <Text style={{color: Theme[context.webappType].Colors.success, fontSize: Theme[context.webappType].Sizes.medium}}>Carrello inviato</Text>
                </>
            }
            ></Alert> 
        </SafeAreaView>
    );
}


export default PickingCart;
import React, { useContext } from 'react';
import { Text, Pressable, View , Image} from 'react-native';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';
import { Theme } from '../theme/Theme';
import CurFormatter from './CurFormatter';

function ProdottoListItem({item,onPress}) {

    const context = useContext(WinWorkGoldContext);
    const priceFormatted = CurFormatter(item.price);
    const priceDiscFormatted = CurFormatter(item.priceDisc);

    return (
        <View style={Theme[context.webappType].styles.generalListItem}>
            <Pressable onPress={() => {
                onPress(item);
            }}>
                <View style={Theme[context.webappType].styles.itemListRow}>
                    <View style={Theme[context.webappType].styles.prodottoImgContainer}>
                        {
                            item.img.length > 0 &&
                            <Image style={Theme[context.webappType].styles.prodottoImg} source={{ uri: context.justServerUrl + item.img[0]}}></Image>
                        }
                        {
                            item.img.length == 0 &&
                            <Image style={Theme[context.webappType].styles.prodottoImg}  source={{ uri: context.placeholderUrl }}></Image>
                        }
                        {/* <Text style={[Theme[context.webappType].styles.itemListLineTexts,Theme[context.webappType].styles.prodottoId]}>{item.id}</Text> */}
                    </View>
                    <View style={[Theme[context.webappType].styles.itemListFillSpace, { display: 'flex', flexShrink: 1 }]}>
                        <View style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                            <Text style={[Theme[context.webappType].styles.itemListLineTexts, {fontWeight: Theme[context.webappType].Weights.bold}]}>{item.id}</Text>
                            {
                                item.title &&
                                <Text style={[Theme[context.webappType].styles.itemListLineTexts, Theme[context.webappType].styles.prodottoDesc]}>{item.title}</Text>
                            }
                            {
                                item.desc &&
                                <Text style={[Theme[context.webappType].styles.itemListLineTexts, Theme[context.webappType].styles.prodottoDesc]}>{item.desc}</Text>
                            }
                            {
                                item.tipo &&
                                <Text>{item.tipo}</Text>
                            }
                            {
                                item.categoria &&
                                <Text>{item.categoria}</Text>
                            }
                            {
                                item.modello &&
                                <Text>{item.modello}</Text>
                            }
                            {
                                item.fornitore &&
                                <Text>{item.fornitore}</Text>
                            }
                        </View>
                        {/* <View style={Theme[context.webappType].styles.itemListFillSpace}/> */}
                        {
                            item.priceDisc > 0 && item.priceDisc < item.price &&
                            <View style={Theme[context.webappType].styles.prodottoPriceBlock}>
                                <Text style={Theme[context.webappType].styles.prodottoOldPrice}> {priceFormatted}</Text>
                                <Text style={Theme[context.webappType].styles.prodottoPrice}> {priceDiscFormatted}</Text>
                            </View>
                        }
                        {
                            item.priceDisc == 0 &&
                            <View style={Theme[context.webappType].styles.prodottoPriceBlock}>
                                <Text style={Theme[context.webappType].styles.prodottoPrice}> {priceFormatted}</Text>
                            </View>
                        }
                    </View>
                </View>
            </Pressable>
        </View>
    );
}

export default ProdottoListItem;
import React, { useContext } from 'react';
import { Pressable, Text, View } from 'react-native';
import { Theme } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function Pagination({page,totPage,changePage}) {

    const context = useContext(WinWorkGoldContext);

    return (
        <>
        {
            totPage > 1 &&
            <View style={Theme[context.webappType].styles.pagination}>
                <Text style={{marginRight: 10}}>Pagina {page}/{totPage}</Text>
                <Pressable style={[Theme[context.webappType].styles.paginationButton,{backgroundColor: page == 1 ? Theme[context.webappType].Colors.primary + 'AA' : Theme[context.webappType].Colors.primary}]} onPress={() => {changePage(page - 1)}} disabled={page == 1}>
                    <Icon name="chevron-back-outline" style={Theme[context.webappType].styles.paginationButtonText}/>
                </Pressable>
                <Pressable style={[Theme[context.webappType].styles.paginationButton,{backgroundColor: page == totPage ? Theme[context.webappType].Colors.primary + 'AA' : Theme[context.webappType].Colors.primary}]} onPress={() => {changePage(page + 1)}} disabled={page == totPage}>
                    <Icon name="chevron-forward-outline" style={Theme[context.webappType].styles.paginationButtonText}/>
                </Pressable>
            </View>
        }
        </>
    );
}

export default Pagination;
import { StyleSheet } from 'react-native';

const primaryColors = {
    basic: {
        primary: '#D8AC48', //#B19D55
        homeButtons: '#161616'
    },
    shop: {
        primary: '#1D4461', //#B19D55
        homeButtons: '#1D4461', //#B19D55
    }
}

const basicColors = {
    overPrimary: '#FFF',
    success: '#008F39',
    info: '#2797F9',
    warning: '#FFEC44',
    danger: '#EB3107',
    text: '#333',
    disabled: '#888',
    white: '#FFF',
    black: '#000',
    lightGray: '#DDD',
    gray: '#AAA',
    transparent: 'transparent'
};

const basicSizes = {
    tiny: 12,
    small: 15,
    medium: 20,
    large: 24,
    extraLarge: 34
};

const basicWeights = {
    light: '100',
    normal: 'normal',
    bold: 'bold'
};

const basicStyles = StyleSheet.create({
    textError: {
        color: basicColors.danger
    },
    textWhite: {
        color: basicColors.white
    },
    elevation: {
        elevation: 8,
        boxShadow: '1px 1px 10px ' + basicColors.black + '2'
    },
    safeArea: {
        flex: 1
    },
    bgImage: {
        flex: 1,
        backgroundColor: '#f8ece3'
    },
    menuView: {
        width: 160,
        marginRight: 10,
        paddingVertical: 50,
        flex: 1,
        justifyContent: 'center'
    },
    view: {
        flex: 1,
        justifyContent: 'flex-start',
        position: 'absolute',
        top: 0,
        left:0,
        width: '100%',
        height: '100%'
    },
    titleView: {
        elevation: 8,
        borderBottomRightRadius: 30,
        borderBottomLeftRadius: 30,
        paddingTop: 30,
        paddingBottom: 15,
        paddingHorizontal: 30,
        display: 'flex',
        flexDirection: 'row'
    },
    titleText: {
        fontSize: basicSizes.large,
        color: basicColors.overPrimary,
        textAlign: 'center',
        flexGrow: 1
    },
    listView: {
        flexGrow: 1,
    },
    menuButton: {
        elevation: 8,
        paddingVertical: 15,
        paddingHorizontal: 15,
        marginHorizontal: 2,
        marginVertical: 2,
        borderRadius: 10,
        alignItems: 'center'
    },
    menuButtonText: {
        textAlign: 'center',
        fontSize: basicSizes.medium,
        fontWeight: basicWeights.normal,
        color: basicColors.white
    },
    menuButtonImg: {
        width: 40,
        height: 40
    },
    riparazioneListItem: {
        padding: 20,
        borderBottomColor: basicColors.lightGray,
        borderBottomWidth: 1
    },
    checkButton: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        paddingHorizontal: 10,
        backgroundColor: basicColors.success
    },
    pageInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        paddingTop: 15,
        paddingLeft: 20,
        paddingRight: 20
    },
    pageInfoStatistiche: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 0,
        paddingRight: 20
    },
    pageInfoText: {
        paddingVertical: 6,
        paddingHorizontal: 12,
        fontSize: basicSizes.small,
        fontWeight: basicWeights.bold,
        borderRadius: 5
    },
    pageInfoTextStatistiche: {
        paddingVertical: 6,
        paddingHorizontal: 10,
        fontSize: basicSizes.tiny,
        fontWeight: basicWeights.bold,
        borderRadius: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5
    },
    itemListRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    emptyItem: {
        padding: 20
    },
    itemListLineTexts: {
        fontSize: basicSizes.small,
        paddingVertical: 2
    },
    itemListFillSpace: {
        flexGrow: 1
    },
    riparazioneCod: {
        fontWeight: basicWeights.bold,
        width: '33%'
    },
    riparazioneDate: {
        textAlign: 'center',
        width: '33%'
    },
    riparazioneReady: {
        textAlign: 'right',
        fontWeight: basicWeights.bold,
        width: '33%'
    },
    titleFillRight: {
        flexGrow: 1,
        fontWeight: basicWeights.bold,
        textTransform: 'uppercase'
    },
    riparazionePrice: {
        textAlign: 'right',
        fontWeight: basicWeights.bold,
        color: basicColors.info,
        fontSize: basicSizes.medium
    },
    blockTitle: {
        padding: 20,
        backgroundColor: basicColors.lightGray,
        borderBottomWidth: 1,
        borderBottomColor: basicColors.gray
    },
    blockTitleText: {
        fontSize: basicSizes.small,
        textTransform: 'uppercase',
    },
    singleBlock: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: basicColors.gray
    },
    singleBlockTitle: {
        fontSize: basicSizes.small
    },
    singleBlockText: {
        fontSize: basicSizes.small,
        fontWeight: basicWeights.bold
    },
    searchBarIcon: {
        color: basicColors.gray,
        fontSize: basicSizes.medium,
        marginRight: 10
    },
    searchBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: basicColors.white,
        paddingVertical: 10,
        paddingHorizontal: 15,
        margin: 10,
        borderRadius: 10
    },
    searchBarInput: {
        flexGrow: 1,
        fontSize: basicSizes.small
    },
    genericButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: basicColors.info,
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 10
    },
    generalListItem: {
        padding: 20,
        borderBottomColor: basicColors.lightGray,
        borderBottomWidth: 1
    },
    prodottoImg: {
        width: '100%',
        height: '100%',
        borderRadius: 10
    },
    scanContainer: {
        width: 150,
        height: '100%'
    },
    galleryContainer: {
        width: '100%',
        paddingVertical: 10,
        paddingHorizontal: 20
    },
    galleryPreviewContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    prodottoImgPreviewContainer: {
        display: 'block',
        width: 70,
        height: 70,
        padding: 5,
        marginBottom: 15
    },
    prodottoImgPreview: {
        display: 'block',
        width: '100%',
        height: '100%',
        borderWidth: 2,
        borderColor: basicColors.lightGray,
        borderRadius: 5
    },
    imgMainContainer: {
        width: '100%',
        height: 320,
        padding: 10,
        display: 'flex',
        alignItems: 'center'
    },
    prodottoImgMain: {
        borderRadius: 15,
        maxWidth: '100%',
        width: 300,
        height: 300
    },
    prodottoImgContainer: {
        width: 120,
        height: 120,
        borderRadius: 10,
        overflow: 'hidden',
        borderColor: basicColors.lightGray,
        borderWidth: 1,
        marginRight: 10,
        padding: 10
    },
    prodottoId: {
        position: 'absolute',
        left: 20,
        top: 15,
        fontWeight: basicWeights.bold
    },
    prodottoDesc: {
        fontWeight: basicWeights.normal
    },
    prodottoPriceBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    prodottoPrice: {
        fontWeight: basicWeights.bold,
        fontSize: basicSizes.medium,
        color: basicColors.info
    },
    prodottoOldPrice: {
        fontSize: basicSizes.small,
        color: basicColors.disabled,
        textDecorationLine: 'line-through',
        marginRight: 10
    },
    menuCounterContainer: {
        position: 'absolute',
        top: 2,
        right: 3,
        width: 30,
        height: 30,
        borderRadius: 3,
        borderTopRightRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    menuCounter: {
        color: basicColors.white,
        textAlign: 'center',
        fontWeight: basicWeights.bold,
        fontSize: basicSizes.small
    },
    loginViewContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loginView: {
        width: 450,
        maxWidth: '80%',
        paddingHorizontal: 20,
        paddingTop: 30,
        paddingBottom: 50,
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '1px 1px 10px ' + basicColors.black + '2'
    },
    loginTitle: {
        fontSize: basicSizes.large,
        fontWeight: basicWeights.light,
        textAlign: 'center',
        marginBottom: 20
    },
    bigPrimaryIcon: {
        fontSize: basicSizes.extraLarge
    },
    loginInput: {
        paddingVertical: 5,
        marginVertical: 10,
        fontSize: basicSizes.medium,
        borderBottomColor: basicColors.gray,
        borderBottomWidth: 1,
        maxWidth: '100%'
    },
    pagination: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    paginationButton: {
        borderRadius: 5,
        marginHorizontal: 3,
        padding: 5,
        minWidth: 30,
        minHeight: 30,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paginationButtonActive: {
        borderWidth: 0
    },
    paginationButtonText: {
        color: basicColors.white,
        fontWeight: basicWeights.bold
    },
    topbarLogout: {
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 5,
        paddingHorizontal: 10
    },
    userMenuBlock: {
        position: 'absolute',
        right: 0,
        top: 57,
        minWidth: 200,
        borderRadius: 0
    },
    userMenuBlockButton: {
        borderTopColor: basicColors.white,
        borderTopWidth: 1,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
        paddingHorizontal: 10,
    }
});

export const Theme = {
    shop: {
        Colors: {
            ...primaryColors,
            ...basicColors
        },
        Sizes: basicSizes,
        Weights: basicWeights,
        styles: StyleSheet.create(
        {
            ...basicStyles,
            bgPrimary: {
                backgroundColor: primaryColors.shop.primary
            },
            textPrimary: {
                color: primaryColors.shop.primary
            },
            titleView: {
                backgroundColor: primaryColors.shop.primary,
                ...basicStyles.titleView
            },
            loginTitle: {
                color: primaryColors.shop.primary,
                ...basicStyles.loginTitle
            },
            bigPrimaryIcon: {
                color: primaryColors.shop.primary,
                ...basicStyles.bigPrimaryIcon
            },
            paginationButton: {
                backgroundColor: primaryColors.shop.primary,
                ...basicStyles.paginationButton
            },
            menuButton: {
                borderColor: primaryColors.shop.homeButtons,
                backgroundColor: primaryColors.shop.homeButtons,
                ...basicStyles.menuButton
            },
            pageInfoText: {
                color: basicColors.white,
                backgroundColor: basicColors.black,
                ...basicStyles.pageInfoText
            },
            pageInfoTextStatistiche: {
                color: basicColors.white,
                backgroundColor: basicColors.black,
                ...basicStyles.pageInfoTextStatistiche
            },
            menuCounterContainer: {
                backgroundColor: basicColors.black,
                ...basicStyles.menuCounterContainer
            },
            loginViewContainer: {
                backgroundColor: primaryColors.shop.primary + '3',
                ...basicStyles.loginViewContainer
            },
            loginView: {
                color: basicColors.black,
                backgroundColor: basicColors.white,
                ...basicStyles.loginView
            },
            paginationButtonActive: {
                borderColor: basicColors.black,
                ...basicStyles.paginationButtonActive
            },
            topbarLogout: {
                backgroundColor: primaryColors.shop.primary,
                color: basicColors.white,
                ...basicStyles.topbarLogout
            },
            userMenuBlock: {
                backgroundColor: primaryColors.shop.primary,
                ...basicStyles.userMenuBlock
            },
            prodottoImgPreviewActive: {
                borderColor: primaryColors.shop.primary
            }
        })
    },
    basic: {
        Colors: {
            ...primaryColors.basic, //#B19D55
            ...basicColors
        },
        Sizes: basicSizes,
        Weights: basicWeights,
        styles: StyleSheet.create(
            {
                ...basicStyles,
                bgPrimary: {
                    backgroundColor: primaryColors.basic.primary
                },
                textPrimary: {
                    color: primaryColors.basic.primary
                },
                titleView: {
                    backgroundColor: primaryColors.basic.primary,
                    ...basicStyles.titleView
                },
                loginTitle: {
                    color: primaryColors.basic.primary,
                    ...basicStyles.loginTitle
                },
                bigPrimaryIcon: {
                    color: primaryColors.basic.primary,
                    ...basicStyles.bigPrimaryIcon
                },
                paginationButton: {
                    backgroundColor: primaryColors.basic.primary,
                    ...basicStyles.paginationButton
                },
                menuButton: {
                    borderColor: primaryColors.basic.homeButtons,
                    backgroundColor: primaryColors.basic.homeButtons,
                    ...basicStyles.menuButton
                },
                pageInfoText: {
                    color: basicColors.white,
                    backgroundColor: basicColors.black,
                    ...basicStyles.pageInfoText
                },
                pageInfoTextStatistiche: {
                    color: basicColors.white,
                    backgroundColor: basicColors.black,
                    ...basicStyles.pageInfoTextStatistiche
                },
                menuCounterContainer: {
                    backgroundColor: basicColors.black,
                    ...basicStyles.menuCounterContainer
                },
                loginViewContainer: {
                    backgroundColor: basicColors.black + '3',
                    ...basicStyles.loginViewContainer
                },
                loginView: {
                    color: basicColors.black,
                    backgroundColor: basicColors.white,
                    ...basicStyles.loginView
                },
                paginationButtonActive: {
                    borderColor: basicColors.black,
                    ...basicStyles.paginationButtonActive
                },
                topbarLogout: {
                    backgroundColor: basicColors.black,
                    color: basicColors.white,
                    ...basicStyles.topbarLogout
                },
                userMenuBlock: {
                    backgroundColor: basicColors.black,
                    ...basicStyles.userMenuBlock
                },
                prodottoImgPreviewActive: {
                    borderColor: primaryColors.basic.primary
                }
            })
    }
}
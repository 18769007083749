import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView, View, Text, Pressable, FlatList, TextInput } from 'react-native';
import { Theme } from '../theme/Theme';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';
import PageHeader from '../components/PageHeader';
import Icon from 'react-native-vector-icons/Ionicons';

import ModalCentered from '../components/Modal';
import MagazzinoListItem from '../components/MagazzinoListItem';
import Alert from '../components/Alert';

import BarReader from '../components/BarReader';
import AsyncStorage from '@react-native-async-storage/async-storage';
import PickingListItem from '../components/PickingListItem';
import SearchBar from '../components/SearchBar';
import Pagination from '../components/Pagination';
import ClienteListItem from '../components/ClienteListItem';
import GiacenzaListItem from '../components/GiacenzaListItem';

function Picking({ navigation }) {

    const context = useContext(WinWorkGoldContext);

    const [modalMagSelectionShow, setModalMagSelectionShow] = useState(false);

    const [alertProductNotFoundShow, setAlertProductNotFoundShow] = useState(false);

    const [modalScanBarCodeRequested, setModalScanBarCodeRequested] = useState(false);
    const [modalScanBarCodeShow, setModalScanBarCodeShow] = useState(false);
    const [barcodeScanned, setBarcodeScanned] = useState('');
    const [manualBarcode,setManualBarcode] = useState('');

    const [totaleClientSearch, setTotaleClientSearch] = useState(0);
    const [pageClientSearch, setPageClientSearch] = useState(1);
    const [totPageClientSearch, setTotPageClientSearch] = useState(1);
    const [clientSearchParam, setClientSearchParam] = useState('');
    const [modalSearchClientShow, setModalSearchClientShow] = useState(false);
    const [searchClientResult, setSearchClientResult] = useState({});

    const [giacenze, setGiacenze] = useState([]);
    const [productScanned, setProductScanned] = useState({});
    const [productInCart, setProductInCart] = useState(false);
    const [modalGiacenzeShow, setModalGiacenzeShow] = useState(false);
    const [alertGiacenzaNotFoundShow, setAlertGiacenzaNotFoundShow] = useState(false);
    const [alertNoMoreToOrderShow, setAlertNoMoreToOrderShow] = useState(false);
    const [lottoSearchParam, setLottoSearchParam] = useState('');
    const [currentPicked, setCurrentPicked] = useState(0);

    const modals = {
        'magSelection': setModalMagSelectionShow,
        'searchClient': setModalSearchClientShow,
        'giacenze': setModalGiacenzeShow
    }

    const changePageClientSearch = async (page) => {
        setPageClientSearch(page);

        try {

            const login = await AsyncStorage.getItem('@log_store');
            const response = await fetch(context.serverUrl + '?azione=clienti&ricerca=' + clientSearchParam + '&pagina=' + page + '&loginp=' + login);
            const json = await response.json();

            setSearchClientResult(json.clienti);
        } catch (error) {
            console.log(error);
        }
    }

    const searchClient = async (search) => {
        setClientSearchParam(search);
        setPageClientSearch(1);

        try {

            const login = await AsyncStorage.getItem('@log_store');
            const response = await fetch(context.serverUrl + '?azione=clienti&ricerca=' + search + '&pagina=1&loginp=' + login);
            const json = await response.json();

            setSearchClientResult(json.clienti);
            setTotaleClientSearch(json.totale);
            setTotPageClientSearch(json.totalePagine);
        } catch (error) {
            console.log(error);
        }
    };

    const setClient = (cliente) => {
        context.picking.setClient(cliente);
        closeModal('searchClient');
    }

    const openModal = (key) => {
        modals[key](true);
    };
    const closeModal = (key) => {
        modals[key](false);
    };

    const handleBarCodeScanned = async (result) => {
        const login = await AsyncStorage.getItem('@log_store');

        try {
            const response = await fetch(context.serverUrl + '?azione=scan_prodotti_inventario&data=' + result + '&loginp=' + login);
            const json = await response.json();

            if (json.success) {

                let already_in_cart = context.picking.cart.filter(picking => picking.codart == json.foundProduct.codart);
                let qta_already_in_cart = already_in_cart.reduce((partialSum, item) => partialSum + ( item.qta != '' ? parseInt(item.qta) : 0), 0);
                let is_already_in_cart = already_in_cart.length > 0;
                let tot_already_in_cart = 0;
                setProductInCart(is_already_in_cart);
                
                const response = await fetch(context.serverUrl + '?azione=get_giacenze&codcli=' + context.picking.client.id + '&codmag=' + context.picking.magazzino.id + '&codart=' + json.foundProduct.codart + '&loginp=' + login);
                const json_giac = await response.json();

                if (json_giac.success) {
                    /* if (json_giac.giacenze.length > 0) { */

                        let elenco_giacenze = json_giac.giacenze;
                        let to_ship = json_giac.tot_righe_ord - qta_already_in_cart;
                        to_ship = to_ship < 0 ? 0 : to_ship;
                        let to_ship_tmp = to_ship;

                        for (let i = 0; i < elenco_giacenze.length; i++) {

                            elenco_giacenze[i].lotto = elenco_giacenze[i].lotto ? elenco_giacenze[i].lotto : '';
                            elenco_giacenze[i].qta = elenco_giacenze[i].giac < to_ship_tmp ? (elenco_giacenze[i].giac > 0 ? elenco_giacenze[i].giac : 0) : to_ship_tmp;

                            if(is_already_in_cart) {
                                elenco_giacenze[i].in_cart = already_in_cart.reduce((partialSum, item) => partialSum + (item.lotto == elenco_giacenze[i].lotto ? (item.qta != '' ? parseInt(item.qta) : 0) : 0), 0);
                            } else {
                                elenco_giacenze[i].in_cart = 0;
                            }

                            tot_already_in_cart += elenco_giacenze[i].in_cart;

                            if (to_ship_tmp > 0) {
                                to_ship_tmp -= elenco_giacenze[i].qta;
                            }
                        }

                        elenco_giacenze = [{
                            'pers': true,
                            'id_giac': 'pers',
                            'scad': '',
                            'lotto': '',
                            'qta': 0
                        },...elenco_giacenze];

                        setGiacenze(elenco_giacenze);
                        updCurrentPicked(elenco_giacenze);

                        setProductScanned({
                            'tot_righe_ord': to_ship,
                            'tot_in_cart': tot_already_in_cart,
                            'codart': json.foundProduct.codart,
                            'desart': json.foundProduct.desart,
                            'barcode': result
                        });

                        setLottoSearchParam('');
                        openModal('giacenze');
                    /* } else {
                        setBarcodeScanned(result);
                        setAlertGiacenzaNotFoundShow(true);
                    } */
                }
            } else {
                setBarcodeScanned(result);
                setAlertProductNotFoundShow(true);
                setManualBarcode('');
            }
        } catch (error) {
            console.log(error);
        }

        setModalScanBarCodeShow(false);
    };

    const editPicking = async (item, newQta) => {
        const newQtaInt = parseInt(newQta);

        //controllo la quantità ordinata dell'articolo
        const login = await AsyncStorage.getItem('@log_store');

        try {
            const response = await fetch(context.serverUrl + '?azione=get_tot_ord&codart=' + item.codart + '&codcli=' + context.picking.client.id + '&loginp=' + login);

            const json = await response.json();

            let tot_righe_ord = json.tot_righe_ord;
            let already_in_cart = context.picking.cart.filter(picking => (picking.codart == item.codart && picking.id != item.id));
            let qta_already_in_cart = already_in_cart.reduce((partialSum, itemtmp) => partialSum + ( itemtmp.qta != '' ? parseInt(itemtmp.qta) : 0), 0);

            const editPicking = { ...item, qta: newQta };

            let loadOnline = true;
            if (newQta === '') {
                loadOnline = false;
            }
            
            if(loadOnline && newQtaInt != 0 && ((tot_righe_ord - (qta_already_in_cart + newQtaInt)) < 0)) {
                setAlertNoMoreToOrderShow(true);
                return;
            }

            if (newQtaInt > 0 || newQta === '') {

                let newPickingCart = [];
                let totPickingCart = context.picking.cart.length;

                for (let i = 0; i < totPickingCart; i++) {
                    let tmpPicking = context.picking.cart[i];

                    if (tmpPicking.id != item.id) {
                        newPickingCart.push(tmpPicking);
                    } else {
                        newPickingCart.push(editPicking);
                    }
                }

                context.picking.setCart(newPickingCart);
            } else if (loadOnline) {
                context.picking.setCart([...(context.picking.cart.filter(picking => picking.id != item.id))]);
            }

            if (loadOnline) {
                const login = await AsyncStorage.getItem('@log_store');

                const picking_string = encodeURIComponent(JSON.stringify([editPicking]));

                try {
                    const response = await fetch(context.serverUrl + '?azione=save_picking&picking=' + picking_string + '&mag=' + context.picking.magazzino.id + '&cli=' + context.picking.client.id + '&utente=' + context.user.get + '&loginp=' + login);

                    const json = await response.json();
                } catch (error) {
                    console.log(error);
                }
            }

        } catch (error) {
            console.log(error);
        }

    }

    const addToPickingCart = async () => {

        let tot_picked = giacenze.reduce((tot, item) =>  tot + item.qta, 0);

        if(tot_picked > 0) {

            const picking_string = encodeURIComponent(JSON.stringify(giacenze));
            const art_string = encodeURIComponent(JSON.stringify(productScanned));
    
            try {
                const login = await AsyncStorage.getItem('@log_store');
                const response = await fetch(context.serverUrl + '?azione=save_picking&picking=' + picking_string + '&art=' + art_string + '&mag=' + context.picking.magazzino.id + '&cli=' + context.picking.client.id + '&utente=' + context.user.get + '&loginp=' + login);
    
                const json = await response.json();
                
                if (json.success) {
                    if(json.picking) {
                        let newPickingCart = [];

                        let added = 0;
                        for(let i = 0; i < giacenze.length; i++) {
                            if(giacenze[i].qta > 0) {
                                newPickingCart.push({
                                    id: json.picking[added].id,
                                    codart: productScanned.codart,
                                    desart: productScanned.desart,
                                    barcode: productScanned.barcode,
                                    ...giacenze[i]
                                });
                                added++;
                            }
                        }

                        newPickingCart.push(...context.picking.cart);
        
                        context.picking.setCart(newPickingCart);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }

        closeModal('giacenze');
    }

    const editLotto = async (item, newLotto) => {
        const editGiac = { ...item, lotto: newLotto };

        let newGiacenze = [];
        let totGiacenze = giacenze.length;

        for (let i = 0; i < totGiacenze; i++) {
            let tmpGiac = giacenze[i];

            if (tmpGiac.id_giac != item.id_giac) {
                newGiacenze.push(tmpGiac);
            } else {
                newGiacenze.push(editGiac);
            }
        }

        setGiacenze(newGiacenze);
    }
    const editScad = async (item, newScad) => {

        if(/^[0-9]{4}[-](0[1-9]|1[0-2])[-](0[1-9]|[1-2][0-9]|3[0-1])$/.test(newScad)) {
            newScad = newScad.split('-');
            newScad = newScad[2] + '/' + newScad[1] + '/' + newScad[0];
        } else if((/^(0[1-9]|[1-2][0-9]|3[0-1])$/.test(newScad) || /^(0[1-9]|[1-2][0-9]|3[0-1])[\/](0[1-9]|1[0-2])$/.test(newScad))) {
            if(!/^(0[1-9]|[1-2][0-9]|3[0-1])[\/]$/.test(item.scad) && !/^(0[1-9]|[1-2][0-9]|3[0-1])[\/](0[1-9]|1[0-2])[\/]$/.test(item.scad)) {
                newScad += '/';
            } else {
                newScad = newScad.slice(0,-1);
            }
        }

        const editGiac = { ...item, scad: newScad };

        let newGiacenze = [];
        let totGiacenze = giacenze.length;

        for (let i = 0; i < totGiacenze; i++) {
            let tmpGiac = giacenze[i];

            if (tmpGiac.id_giac != item.id_giac) {
                newGiacenze.push(tmpGiac);
            } else {
                newGiacenze.push(editGiac);
            }
        }

        setGiacenze(newGiacenze);
    }

    const editGiac = async (item, newQta) => {
        const newQtaInt = parseInt(newQta);
        const editGiac = { ...item, qta: newQta };

        if (newQta !== '') {
            let tmpCurrentPicked = giacenze.reduce((totPicked, itemtmp) => totPicked + ( (itemtmp.qta != '' && itemtmp.id_giac != item.id_giac) ? parseInt(itemtmp.qta) : 0), 0);

            if((productScanned.tot_righe_ord - (tmpCurrentPicked + newQtaInt)) < 0) {
                setAlertNoMoreToOrderShow(true);
                return;
            }
        }

        if (newQtaInt >= 0 || newQta === '') {

            let newGiacenze = [];
            let totGiacenze = giacenze.length;

            for (let i = 0; i < totGiacenze; i++) {
                let tmpGiac = giacenze[i];

                if (tmpGiac.id_giac != item.id_giac) {
                    newGiacenze.push(tmpGiac);
                } else {
                    newGiacenze.push(editGiac);
                }
            }

            setGiacenze(newGiacenze);
            updCurrentPicked(newGiacenze);
        }

    }

    const selectMag = (mag) => {
        context.picking.setMagazzino(mag);
        closeModal('magSelection');
    }

    const giacenzeFiltered = () => {  //(se non c'è niente a  ricerca E (lotto e scadenza son vuoti oppure la qta > 0)) OPPURE se il lotto è filtrato da ricerca in ogni caso lo mostro
        return giacenze.filter(giac => {
            if(!lottoSearchParam) {
                return giac.pers || giac.giac > 0 || (giac.lotto + giac.scad) == '' || giac.qta > 0;
            }
            return giac.lotto && giac.lotto.includes(lottoSearchParam);
        });
    }

    const loadPickingCart = async () => {

        const login = await AsyncStorage.getItem('@log_store');

        try {
            const response = await fetch(context.serverUrl + '?azione=get_picking&mag=' + context.picking.magazzino.id + '&cli=' + context.picking.client.id + '&utente=' + context.user.get + '&loginp=' + login);

            const json = await response.json();

            if(json.success) {
                context.picking.setCart(json.picking);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const newClientSearch = () => {
        searchClient('');
        openModal('searchClient');
    }

    const updCurrentPicked = (newGiacenze) => {
        let current = newGiacenze.reduce((totPicked, item) => totPicked + ( item.qta != '' ? parseInt(item.qta) : 0), 0);
        setCurrentPicked(current);
    }

    useEffect(() => {
        if (!context.picking.client.id) {
            newClientSearch();
        } else {

            loadPickingCart();
        }
    }, [context.picking.client,context.picking.magazzino]);

    return (
        <SafeAreaView style={Theme[context.webappType].styles.safeArea}>
            <View style={Theme[context.webappType].styles.view}>
                <PageHeader goBack={() => navigation.goBack()} title={"Picking - " + context.user.get} ></PageHeader>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'between', alignItems: 'center', paddingVertical: 15, paddingHorizontal: 25, width: '100%' }}>
                    <View style={{ flexShrink: 1 }}>
                        <Text>Cliente selezionato: {context.picking.client.id}</Text>
                        <Text style={{ fontWeight: Theme[context.webappType].Weights.bold, fontSize: Theme[context.webappType].Sizes.medium }}>{context.picking.client.desc}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexGrow: 1 }}>
                        <Pressable style={[Theme[context.webappType].styles.genericButton, { color: Theme[context.webappType].Colors.black, backgroundColor: Theme[context.webappType].Colors.info }]} onPress={newClientSearch}>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}><Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold, marginRight: 10 }]}>Cambia</Text><Icon name="refresh-outline" style={{ fontSize: Theme[context.webappType].Sizes.medium, color: Theme[context.webappType].Colors.white }} /></View>
                        </Pressable>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'between', alignItems: 'center', paddingVertical: 15, paddingHorizontal: 25 }}>
                    <View style={{ flexShrink: 1 }}>
                        <Text>Magazzino selezionato:</Text>
                        {
                            context.picking.magazzino.id ?
                                <Text style={{ fontWeight: Theme[context.webappType].Weights.bold, fontSize: Theme[context.webappType].Sizes.medium }}>{context.picking.magazzino.descr}</Text>
                                :
                                <Text>magazzino non selezionato</Text>
                        }
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexGrow: 1 }}>
                        <Pressable style={[Theme[context.webappType].styles.genericButton, { color: Theme[context.webappType].Colors.black, backgroundColor: Theme[context.webappType].Colors.info }]} onPress={() => { openModal('magSelection') }}>
                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}><Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold, marginRight: 10 }]}>Cambia</Text><Icon name="refresh-outline" style={{ fontSize: Theme[context.webappType].Sizes.medium, color: Theme[context.webappType].Colors.white }} /></View>
                        </Pressable>
                    </View>
                </View>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5, paddingBottom: 10, paddingHorizontal: 10 }}>
                    <Pressable style={[Theme[context.webappType].styles.genericButton, { width: '100%', color: Theme[context.webappType].Colors.black, backgroundColor: Theme[context.webappType].Colors.primary }]} onPress={() => { setModalScanBarCodeRequested(true) }}>
                        <View style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}><Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold, marginRight: 10 }]}>Scannerizza codice a barre</Text><Icon name="barcode-outline" style={{ fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.white }} /></View>
                    </Pressable>
                </View>
                <Text style={{ fontSize: Theme[context.webappType].Sizes.tiny, textAlign: 'center' }}>Oppure inseriscilo a mano</Text>
                <View style={{ display: "flex", flexDirection: 'row', justifyContent: "center", alignItems:"center", borderBottomWidth: 1, borderBottomColor: Theme[context.webappType].Colors.gray, paddingTop: 5, paddingBottom: 10, paddingHorizontal: 10 }}>
                    <TextInput style={{padding: 8,marginHorizontal: 5,width: 100, borderRadius: 5, fontSize: Theme[context.webappType].Sizes.large, borderColor: Theme[context.webappType].Colors.lightGray, borderWidth: 1, textAlign: 'center', flexGrow: 1 }} value={manualBarcode} onChangeText={setManualBarcode}/>
                    <Pressable style={[Theme[context.webappType].styles.genericButton,{color: Theme[context.webappType].Colors.white,backgroundColor: Theme[context.webappType].Colors.success}]} onPress={() => {if(manualBarcode != '') { handleBarCodeScanned(manualBarcode); } }}>
                        <Icon name="checkmark-outline" style={{fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.white}} />
                    </Pressable>
                </View>

                {
                    context.picking.cart && context.picking.cart.length > 0 ?
                    <FlatList
                        data={context.picking.cart}
                        renderItem={({ item }) => <PickingListItem item={item} handleDelete={() => { editPicking(item, 0) }} handleEdit={(newQta) => { let newQtaInt = parseInt(newQta); if (newQtaInt > 0 || newQta === '') editPicking(item, newQta) }}></PickingListItem>}
                        keyExtractor={item => item.id}
                    />
                    :
                    <Text style={Theme[context.webappType].styles.emptyItem}>Niente nel carrello</Text>
                }

                {/* <View style={[Theme[context.webappType].styles.itemListRow, { justifyContent: 'center', borderTopWidth: 1, borderTopColor: Theme[context.webappType].Colors.gray, paddingHorizontal: 15, paddingVertical: 10}]}>
                    <Pressable style={[Theme[context.webappType].styles.genericButton,{backgroundColor: (context.picking.client.id == 0 || (context.picking.cart && context.picking.cart.length == 0)) ? Theme[context.webappType].Colors.success + '55' : Theme[context.webappType].Colors.success}]} onPress={() => { navigation.navigate('PickingCart') }} disabled={context.picking.client.id == 0 || (context.picking.cart && context.picking.cart.length == 0)}>
                        <Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold}]}>Carrello di picking</Text>
                    </Pressable>
                </View> */}
            </View>
            <ModalCentered handleClose={() => { closeModal('giacenze') }} title={"Art." + productScanned.codart + " a magazzino"} modalVisible={modalGiacenzeShow} content=
                {
                    <>
                        <View style={{ display: 'flex', flexDirection: 'row', paddingVertical: 2, alignItems: 'center', paddingHorizontal: 10 }}>
                            <View style={{ flex: 3 }}>
                                <Text>{productScanned.desart}</Text>
                            </View>
                            <View style={{ flex: 2 }}>
                                {
                                    productInCart &&
                                    <Text style={{ textAlign: 'right', color: Theme[context.webappType].Colors.success }}>Qta.Carrello: {productScanned.tot_in_cart}</Text>
                                }
                                <Text style={{ textAlign: 'right' }}>Qta. da Sped.: {productScanned.tot_righe_ord}</Text>
                                <Text style={{ textAlign: 'right' }}>Qta. in Prel.: {currentPicked}</Text>
                            </View>
                        </View>
                        <SearchBar handleSearch={setLottoSearchParam} extraInputStyle={{borderColor: Theme[context.webappType].Colors.lightGray, borderWidth: 1}} placeholder="Cerca lotto" wButton={false} wIcon={true} renderButton={
                            <Icon name="search-outline" style={{ fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.primary }} />
                        }></SearchBar>
                        {
                            (giacenzeFiltered().length > 0) ?
                                (<FlatList
                                    data={giacenzeFiltered()}
                                    renderItem={({ item }) => <GiacenzaListItem item={item} handleEdit={(newQta) => { let newQtaInt = parseInt(newQta); if (newQtaInt >= 0 || newQta === '') editGiac(item, newQta) }} handleEditLotto={(newLotto) => { editLotto(item, newLotto); }} handleEditScad={(newScad) => { editScad(item, newScad); }}></GiacenzaListItem>}
                                    keyExtractor={item => item.id_giac}
                                />)
                                :
                                (<Text style={Theme[context.webappType].styles.emptyItem}>Nessun risultato trovato</Text>)
                        }
                        <View style={{ display: 'flex', justifyContent: 'center', borderTopWidth: 1, borderTopColor: Theme[context.webappType].Colors.gray, paddingHorizontal: 15, paddingVertical: 10 }}>
                            {
                                productScanned.tot_righe_ord == 0 &&
                                <Text style={{ color: Theme[context.webappType].Colors.danger, textAlign: 'center', paddingBottom: 5 }}>La quantità inserita equivale alla quantità ordinata</Text>
                            }
                            <Pressable style={[Theme[context.webappType].styles.genericButton, { backgroundColor: (currentPicked == 0 || productScanned.tot_righe_ord == 0) ? Theme[context.webappType].Colors.success + '55' : Theme[context.webappType].Colors.success, justifyContent: 'center' }]} onPress={addToPickingCart} disabled={currentPicked == 0 || productScanned.tot_righe_ord == 0}>
                                <Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold, textAlign: 'center' }]}>Aggiungi al carrello</Text>
                            </Pressable>
                        </View>
                    </>
                }
            ></ModalCentered>
            <ModalCentered handleClose={() => { closeModal('searchClient') }} title="Seleziona cliente" modalVisible={modalSearchClientShow} content=
                {
                    <>
                        <SearchBar handleSearch={searchClient} placeholder="Cerca cliente" wButton={false} wIcon={true} renderButton={
                            <Icon name="search-outline" style={{ fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.primary }} />
                        }></SearchBar>
                        <View style={Theme[context.webappType].styles.pageInfo}>
                            <Text style={Theme[context.webappType].styles.pageInfoText}>N° {totaleClientSearch}</Text>
                            <Pagination page={pageClientSearch} totPage={totPageClientSearch} changePage={changePageClientSearch}></Pagination>
                        </View>
                        {
                            (searchClientResult.length > 0) ?
                                (<FlatList
                                    data={searchClientResult}
                                    renderItem={({ item }) => <ClienteListItem item={item} onPress={() => { setClient(item) }}></ClienteListItem>}
                                    keyExtractor={item => item.id}
                                />)
                                :
                                (<Text style={Theme[context.webappType].styles.emptyItem}>Nessun risultato trovato</Text>)
                        }
                    </>
                }
            ></ModalCentered>
            <ModalCentered handleClose={() => { closeModal('magSelection') }} title="Seleziona Magazzino" modalVisible={modalMagSelectionShow} content=
                {
                    <>
                        {
                            (context.magazzini.get && context.magazzini.get.length > 0) ?
                                (<FlatList
                                    data={context.magazzini.get}
                                    renderItem={({ item }) => <MagazzinoListItem item={item} onPress={() => { selectMag(item) }}></MagazzinoListItem>}
                                    keyExtractor={item => item.id}
                                />)
                                :
                                (<Text style={Theme[context.webappType].styles.emptyItem}>Nessun risultato trovato</Text>)
                        }
                    </>
                }
            ></ModalCentered>
            <BarReader
                modalScanBarCodeRequested={modalScanBarCodeRequested}
                setModalScanBarCodeRequested={setModalScanBarCodeRequested}
                modalScanBarCodeShow={modalScanBarCodeShow}
                setModalScanBarCodeShow={setModalScanBarCodeShow}
                afterBarCodeScanned={handleBarCodeScanned}
            ></BarReader>
            <Alert visibleTime={0} modalVisible={alertProductNotFoundShow} handleClose={() => { setAlertProductNotFoundShow(false); }} overlayColor={Theme[context.webappType].Colors.white + 'D'} content=
                {
                    <View style={{ maxWidth: '80%' }}>
                        <Icon name="warning-outline" style={{ fontSize: Theme[context.webappType].Sizes.extraLarge, color: Theme[context.webappType].Colors.danger }} />
                        <Text style={{ color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium }}>Nessun prodotto trovato</Text>
                        <Text style={{ color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium }}>Codice letto:</Text>
                        <Text style={{ color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium, fontWeight: Theme[context.webappType].Weights.bold }}>{barcodeScanned}</Text>
                    </View>
                }
            ></Alert>
            <Alert visibleTime={0} modalVisible={alertGiacenzaNotFoundShow} handleClose={() => { setAlertGiacenzaNotFoundShow(false); }} overlayColor={Theme[context.webappType].Colors.white + 'D'} content=
                {
                    <View style={{ maxWidth: '80%' }}>
                        <Icon name="warning-outline" style={{ fontSize: Theme[context.webappType].Sizes.extraLarge, color: Theme[context.webappType].Colors.danger }} />
                        <Text style={{ color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium }}>Non trovato a magazzino</Text>
                        <Text style={{ color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium }}>Codice letto:</Text>
                        <Text style={{ color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium, fontWeight: Theme[context.webappType].Weights.bold }}>{barcodeScanned}</Text>
                    </View>
                }
            ></Alert>
            <Alert visibleTime={0} modalVisible={alertNoMoreToOrderShow} handleClose={() => { setAlertNoMoreToOrderShow(false); }} overlayColor={Theme[context.webappType].Colors.white + 'D'} content=
                {
                    <View style={{ maxWidth: '80%' }}>
                        <Icon name="warning-outline" style={{ fontSize: Theme[context.webappType].Sizes.extraLarge, color: Theme[context.webappType].Colors.danger }} />
                        <Text style={{ color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium }}>Quantità inserita maggiore di quantità ordinata</Text>
                    </View>
                }
            ></Alert>
        </SafeAreaView>
    );
}


export default Picking;
import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView , View , Text , Pressable , ImageBackground , TextInput } from 'react-native';
import { Theme } from '../theme/Theme';
import {WinWorkGoldContext} from '../context/WinWorkGoldContext';
import bgImage from '../assets/home-bg.jpg';
import bgImageShop from '../assets/home-bg-shop-mobile.jpg';
import bgImageShopApp from '../assets/home-bg-shop_app.jpg';
import Icon from 'react-native-vector-icons/Ionicons';
import * as Crypto from 'expo-crypto';

function Login({navigation}) {

    const context = useContext(WinWorkGoldContext);
	const [errorLogin, setErrorLogin] = useState(false);
	const [username, setUsername] = useState('');
	const [psw, setPsw] = useState('');

    const encrypt = async () => {
        return await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.SHA256,
            username.toLocaleLowerCase() + '|' + psw
        );
    }

    const onChangeUsername = (value) => {
        setErrorLogin(false);
        setUsername(value);
    }
    const onChangePassword = (value) => {
        setErrorLogin(false);
        setPsw(value);
    }

    const handleSubmit = async () => {
        //SCOMMENTA PER TEST
        /* if(await context.login.try(username + '|' + psw)) {
            navigation.navigate('Home');
        } else {
            setErrorLogin(true);
        } */

        if(await context.login.try(await encrypt())) {
            navigation.navigate('Home');
        } else {
            setErrorLogin(true);
        }
    }
    
    useEffect(() => {
        if(context.login.get) {
            navigation.navigate('Home');
        }
    },[context.login.get]);

    return(
        <SafeAreaView style={Theme[context.webappType].styles.safeArea}>
            <ImageBackground source={context.webappType == 'shop' ? context.isApp ? bgImageShopApp : bgImageShop : bgImage} resizeMode={context.webappType == 'shop' ? context.isApp ? 'cover' : 'contain' : 'cover'} style={Theme[context.webappType].styles.bgImage}>
                <View style={Theme[context.webappType].styles.loginViewContainer}>
                    <View style={Theme[context.webappType].styles.loginView}>
                        <Icon name="person-circle-outline" style={Theme[context.webappType].styles.bigPrimaryIcon} />
                        <Text style={Theme[context.webappType].styles.loginTitle}>Login</Text>
                        <TextInput style={Theme[context.webappType].styles.loginInput} placeholder="Username" onChangeText={onChangeUsername}/>
                        <TextInput secureTextEntry={true} style={Theme[context.webappType].styles.loginInput} placeholder="Password" onChangeText={onChangePassword}/>

                        {
                            errorLogin &&
                            <Text style={Theme[context.webappType].styles.textError}>Username e/o password errati</Text>
                        }

                        <Pressable style={[Theme[context.webappType].styles.genericButton,Theme[context.webappType].styles.bgPrimary,Theme[context.webappType].styles.elevation,{marginTop: 15}]} onPress={handleSubmit}>
                            <Text style={Theme[context.webappType].styles.menuButtonText}>Accedi</Text>
                        </Pressable>
                    </View>
                </View>
            </ImageBackground>
        </SafeAreaView>
    );
}

export default Login;
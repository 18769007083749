import React, { useContext } from 'react';
import { Text, Pressable, View } from 'react-native';
import { Theme } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function PageHeader({goBack , title}) {

    const context = useContext(WinWorkGoldContext);

    return (
        <View style={Theme[context.webappType].styles.titleView}>
            <Pressable onPress={goBack}>
                <Icon name="return-up-back-outline" color={Theme[context.webappType].Colors.overPrimary} size={Theme[context.webappType].Sizes.extraLarge} />
            </Pressable>
            <Text style={Theme[context.webappType].styles.titleText}>{title}</Text>
            <Icon name="return-up-back-outline" color={Theme[context.webappType].Colors.transparent} size={Theme[context.webappType].Sizes.extraLarge} />
        </View>
    );
}

export default PageHeader;
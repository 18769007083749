import 'react-native-gesture-handler';
import { NavigationContainer } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Login from './screens/Login';
import Home from './screens/Home';
import Clienti from './screens/Clienti';
import Fornitori from './screens/Fornitori';
import Prodotti from './screens/Prodotti';
import Riparazioni from './screens/Riparazioni';
import Statistiche from './screens/Statistiche';
import Vendita from './screens/Vendita';
import Inventario from './screens/Inventario';
import Picking from './screens/Picking';
import PickingCart from './screens/PickingCart';
import RiparazioneDetail from './screens/RiparazioneDetail';
import ProdottoDetail from './screens/ProdottoDetail';
import ClienteDetail from './screens/ClienteDetail';
import FornitoreDetail from './screens/FornitoreDetail';

import { WinWorkGoldContext } from './context/WinWorkGoldContext';
import { createStackNavigator } from '@react-navigation/stack';

import imgRiparazioni from './assets/riparazioni.png';
import imgProdotti from './assets/prodotti.png';
import imgClienti from './assets/clienti.png';
import imgFornitori from './assets/fornitori.png';
import imgVendita from './assets/vendita.png';
import imgStatistiche from './assets/statistiche.png';
import imgInventario from './assets/inventario.png';
import imgPicking from './assets/picking.png';
import WebappTypeConf from './assets/WebappTypeConf.json';

const AppStack = createStackNavigator();

export default function App() {

	const [login, setLogin] = useState('');
	const [user, setUser] = useState(0);
	const [cart, setCart] = useState([]);
	const [pickingCart, setPickingCart] = useState([]);
	const [cartCounter, setCartCounter] = useState(0);
	const [cartClient, setCartClient] = useState({ id: 0, desc: '' });
	const [pickingClient, setPickingClient] = useState({ id: 0, desc: '' });
	const [pickingMagazzino, setPickingMagazzino] = useState({ id: 0, desc: '' });
	const [pickingMagDefault, setPickingMagDefault] = useState(0);
	const [userType, setUserType] = useState('ST');
	const [clienti, setClienti] = useState([]);
	const [fornitori, setFornitori] = useState([]);
	const [prodotti, setProdotti] = useState([]);
	const [posizioni, setPosizioni] = useState([]);
	const [riparatori, setRiparatori] = useState([]);
	const [riparazioni, setRiparazioni] = useState([]);
	const [magazzini, setMagazzini] = useState([]);
	const [movimenti, setMovimenti] = useState([]);
	const [inventarioNoCheckBarcodeErr, setInventarioNoCheckBarcodeErr] = useState(false);

	const [wBarcodeVb, setWBarcodeVb] = useState(false);
	const [wBarcodeRip, setWBarcodeRip] = useState(false);
    const [hasCameraPermission, setHasCameraPermission] = useState(null);

	const [webappType, setWebappType] = useState(WebappTypeConf.webappType); //or shop

	const placeholderUrl = 'https://placehold.co/300x300';
	const justServerUrl = WebappTypeConf.webappType == 'shop' ? 'https://shop.lucgel.it/' : 'https://gold.winwork-webapp.it/';
	/* const justServerUrl = 'https://gold.winwork-webapp.it/'; */
	/* const justServerUrl = '/'; */
	/* const justServerUrl = 'https://localhost/'; */
	const serverUrl = justServerUrl + 'winwork_gold/funzioni/azioni.php';

	const [menu, setMenu] = useState([
		{
			title: "Riparazioni",
			component: Riparazioni,
			image: imgRiparazioni,
			webappTypesExc: ['shop'],
			userType: ['SP']
		},
		{
			title: "Prodotti",
			component: Prodotti,
			image: imgProdotti,
			userType: ['SP']
		},
		{
			title: "Clienti",
			component: Clienti,
			image: imgClienti,
			userType: ['SP']
		},
		{
			title: "Fornitori",
			component: Fornitori,
			image: imgFornitori,
			userType: ['SP']
		},
		{
			title: "Vendita",
			component: Vendita,
			image: imgVendita,
			counter: "cart",
			userType: ['SP']
		},
		{
			title: "Statistiche",
			component: Statistiche,
			image: imgStatistiche,
			userType: ['SP']
		},
		{
			title: "Inventario",
			component: Inventario,
			image: imgInventario/* ,
			webappTypesExc: ['shop'] */
		},
		{
			title: "Picking",
			component: Picking,
			image: imgPicking/* ,
			webappTypesExc: ['basic'] */
		}
	]);

    const storeLogin = async (value) => {
        try {
			setLogin(value);
			await AsyncStorage.setItem('@log_store', value)
        } catch (e) {
          // saving error
          console.log(e);
        }
    }

    const logout = async () => {
		storeLogin('');
		fetch(serverUrl + '?azione=logout');
	}

    const tryLogin = async (login) => {
        
        try {
            const response = await fetch(serverUrl + '?azione=login&login=' + login);
            const json = await response.json();

            if(json.stato == 'success') {
                storeLogin(login);
				setUser(json.user);

				setPickingMagDefault(json.user_mag_default);
				setUserType(json.tipo_user);
				return true;
            } else {
				return false;
            }
        } catch (error) {
            console.log(error);
        }
    }
	
	const store = {
		serverUrl,
		justServerUrl,
		placeholderUrl,
		login: { get: login,logout: logout, set: storeLogin, try: tryLogin},
		user: { get: user, type: userType },
		cart: { get: cart, set: setCart, counter: cartCounter, setCounter: setCartCounter, client: cartClient, setClient: setCartClient},
		menu: { get: menu, set: setMenu},
		clienti: { get: clienti, set: setClienti},
		fornitori: { get: fornitori, set: setFornitori},
		prodotti: { get: prodotti, set: setProdotti},
		posizioni: { get: posizioni, set: setPosizioni},
		riparatori: { get: riparatori, set: setRiparatori},
		riparazioni: { get: riparazioni, set: setRiparazioni},
		magazzini: { get: magazzini, set: setMagazzini},
		movimenti: { get: movimenti, set: setMovimenti},
		inventarioNoCheckBarcodeErr: { get: inventarioNoCheckBarcodeErr, set: setInventarioNoCheckBarcodeErr},
		picking: { client: pickingClient, setClient: setPickingClient, magazzino: pickingMagazzino, setMagazzino: setPickingMagazzino, cart: pickingCart, setCart: setPickingCart },
		cameraPermission: { get: hasCameraPermission, set: setHasCameraPermission},
		wBarcode: { vb: wBarcodeVb, rip: wBarcodeRip },
		webappType,
		isApp: true
	};

	const checkLogin = async () => {
		try {
			const value = await AsyncStorage.getItem('@log_store')
			if(value !== null && value != '') {
				return await tryLogin(value);
			}
			return false;
		} catch(e) {
			// error reading value
			console.log(e);
			return false;
		}
	}
    
    useEffect(() => {

		async function fetchData() {

			const login = await AsyncStorage.getItem('@log_store');
			
			if(await(checkLogin())) {

				try {
					const response = await fetch(serverUrl + '?azione=clienti&ricerca=fittizio&pagina=1&loginp=' + login);
					const json = await response.json();
					
					if(json.clienti.length > 0) {
						setCartClient(json.clienti[0]);
					}
				} catch(e) {
					// error reading value
					console.log(e);
				}
	
				try {
					const response = await fetch(serverUrl + '?azione=get_datgen&loginp=' + login);
					const json = await response.json();
					setWBarcodeVb(json.w_barcode_vb);
					setWBarcodeRip(json.w_barcode_rip);
					setInventarioNoCheckBarcodeErr(json.nocnt_barcode_err);
				} catch(e) {
					// error reading value
					console.log(e);
				}

				try {
					const response = await fetch(serverUrl + '?azione=magazzini&loginp=' + login);
					const json = await response.json();
			
					setMagazzini(json.magazzini);

					let magDefault = json.magazzini.filter(mag => mag.id == pickingMagDefault);
					if(magDefault.length > 0) {
						setPickingMagazzino(magDefault[0]);
					}


				} catch (error) {
					console.log(error);
				}
			}
		}

		fetchData();
    },[login]);

	return (
		<NavigationContainer>
			<WinWorkGoldContext.Provider value={store}>
				<AppStack.Navigator screenOptions={{headerMode: 'none', cardStyle: { backgroundColor: '#f8ece3' }}}>
					<AppStack.Screen name="Login"  component={Login}/>
					<AppStack.Screen name="Home"  component={Home}/>
					{
						store.menu.get.map((screen,index) => (
							(!screen.webappTypesExc || (!screen.webappTypesExc.includes(webappType))) &&
							<AppStack.Screen key={index} name={screen.title}  component={screen.component}/>
						))
					}
					<AppStack.Screen name="PickingCart"  component={PickingCart}/>
					<AppStack.Screen name="RiparazioneDetail"  component={RiparazioneDetail}/>
					<AppStack.Screen name="ProdottoDetail"  component={ProdottoDetail}/>
					<AppStack.Screen name="ClienteDetail"  component={ClienteDetail}/>
					<AppStack.Screen name="FornitoreDetail"  component={FornitoreDetail}/>
				</AppStack.Navigator>
			</WinWorkGoldContext.Provider>
		</NavigationContainer>
	);
}
import React, { useContext } from 'react';
import { Text, Pressable, View , Image} from 'react-native';
import { Theme } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import CurFormatter from './CurFormatter';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function CartListItem({item, handleDelete, handleEdit}) {

    const context = useContext(WinWorkGoldContext);

    const priceFormatted = CurFormatter(item.price);
    const priceNettoFormatted = CurFormatter(item.netto);
    const priceTotFormatted = CurFormatter(item.valore);

    return (
        <View style={{flex:1,padding: 10, borderBottomColor: Theme[context.webappType].Colors.gray, borderBottomWidth: 1}}>
            <View style={{flex: 1, flexDirection: 'row',alignItems: 'center',justifyContent: 'center'}}>
                <View style={{flex:1}}>
                    <View style={{display: 'flex', flexDirection: 'column'}}>
                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                            <View style={{flex:2}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Codice</Text>
                            </View>
                            <View style={{flex:4}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Descrizione</Text>
                            </View>
                        </View>

                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                            <View style={{flex:2}}>
                                <Text>{item.id}</Text>
                            </View>
                            <View style={{flex:4, flexWrap: 'wrap'}}>
                                <Text>{item.title} {item.desc}</Text>
                            </View>
                        </View>

                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                            <View style={{flex:1,paddingHorizontal: 2}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>UM</Text>
                            </View>
                            <View style={{flex:1}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Q.ta</Text>
                            </View>
                            <View style={{flex:2}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Prezzo</Text>
                            </View>
                            <View style={{flex:2}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Sconti</Text>
                            </View>
                            <View style={{flex:2}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>P.Netto</Text>
                            </View>
                            <View style={{flex:2}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Valore</Text>
                            </View>
                        </View>

                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                            <View style={{flex:1,paddingHorizontal: 2}}>
                                <Text>{item.UM}</Text>
                            </View>
                            <View style={{flex:1}}>
                                <Text>{item.qta}</Text>
                            </View>
                            <View style={{flex:2}}>
                                <Text>{priceFormatted}</Text>
                            </View>
                            <View style={{flex:2}}>
                                <Text>
                                    {
                                        item.sc1 > 0 &&
                                        item.sc1 + '% '
                                    }
                                    {
                                        item.sc2 > 0 &&
                                        item.sc2 + '% '
                                    }
                                    {
                                        item.sc3 > 0 &&
                                        item.sc3 + '% '
                                    }
                                    {
                                        item.sc4 > 0 &&
                                        item.sc4 + '%'
                                    }
                                </Text>
                            </View>
                            <View style={{flex:2}}>
                                <Text>{priceNettoFormatted}</Text>
                            </View>
                            <View style={{flex:2}}>
                                <Text>{priceTotFormatted}</Text>
                            </View>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Pressable onPress={handleDelete} style={{ paddingHorizontal: 10 }}>
                                <Icon name="trash-sharp" style={{fontSize: Theme[context.webappType].Sizes.medium, color: Theme[context.webappType].Colors.black}} />
                            </Pressable>
                            <Pressable onPress={handleEdit} style={{ paddingHorizontal: 10 }}>
                                <Icon name="pencil-sharp" style={{fontSize: Theme[context.webappType].Sizes.medium, color: Theme[context.webappType].Colors.black}} />
                            </Pressable>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default CartListItem;
import React, { useContext, useState } from 'react';
import { Text , Pressable , View , Image, ScrollView } from 'react-native';
import { Theme } from '../theme/Theme';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function GalleryWPreviews({ images }) {

    const context = useContext(WinWorkGoldContext);
    const [activeImg,setActiveImg] = useState(0);

    return (
        <View style={{flex:1,...Theme[context.webappType].styles.galleryContainer}}>
            {
                <View style={Theme[context.webappType].styles.imgMainContainer}>
                    <Image style={Theme[context.webappType].styles.prodottoImgMain}  source={images[activeImg].source}></Image>
                </View>
            }
            <ScrollView style={{flexGrow:1,flex:1, overflow: 'auto'}}>
                <View style={Theme[context.webappType].styles.galleryPreviewContainer}>
                    {
                        images.map((image,index) => {

                            var completeStyle = Theme[context.webappType].styles.prodottoImgPreview;
                            if(index == activeImg) {
                                completeStyle = [
                                    completeStyle,
                                    Theme[context.webappType].styles.prodottoImgPreviewActive
                                ]
                            }

                            return (
                                <Pressable style={Theme[context.webappType].styles.prodottoImgPreviewContainer} key={index} onPress={() => {setActiveImg(index)}}>
                                    <Image style={completeStyle}  source={image.source}></Image>
                                </Pressable>
                            )
                        })
                    }
                </View>
            </ScrollView>
        </View>
    );
}

export default GalleryWPreviews;
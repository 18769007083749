import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import { Theme } from '../theme/Theme';
import CurFormatter from './CurFormatter';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function StatisticaClienteListItem({item}) {
    
    const context = useContext(WinWorkGoldContext);
    const priceTotFormatted = CurFormatter(item.netto);

    return (
        <View style={{flex:1,padding: 10, borderBottomColor: Theme[context.webappType].Colors.gray, borderBottomWidth: 1}}>
            <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                <View style={{flex:1}}>
                    <Text>{item.codcli}</Text>
                </View>
                <View style={{flex:3, flexWrap: 'wrap'}}>
                    <Text>{item.ragsoc}</Text>
                </View>
                <View style={{flex:1, textAlign: 'right'}}>
                    <Text>{item.qta}</Text>
                </View>
                <View style={{flex:2, textAlign: 'right'}}>
                    <Text>{priceTotFormatted}</Text>
                </View>
            </View>
        </View>
    );
}

export default StatisticaClienteListItem;
import React, { useContext } from 'react';
import { Text, Pressable, View } from 'react-native';
import { Swipeable } from 'react-native-gesture-handler';
import { Theme } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import GenericButton from './GenericButton';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function RiparazioneListItem({item,onPress}) {

    const context = useContext(WinWorkGoldContext);
    const itemReadyText = item.ready ? 'SI' : 'NO';
    const itemReadyTextColor = item.ready ? Theme[context.webappType].Colors.success : Theme[context.webappType].Colors.black;
    const priceFormatted = item.price.toFixed(2);
    const pricePrevFormatted = item.pricePrev.toFixed(2);
    const priceCostFormatted = item.cost.toFixed(2);

    /* const priceToView = item.cost > 0 ? priceCostFormatted : item.price > 0 ? priceFormatted : pricePrevFormatted; */
    const priceToView = priceFormatted;

    const itemId = item.id2 ? item.id + '-' + item.id2 : item.id;

    const rightActions = () => {
        return (
            <Pressable>
            {
                !item.ready &&
                    <View style={Theme[context.webappType].styles.checkButton}>
                        <Icon name="checkmark-outline" color={Theme[context.webappType].Colors.white} size={Theme[context.webappType].Sizes.extraLarge} />
                    </View>
            }
            </Pressable>
        );
    }

    return (
        <View style={Theme[context.webappType].styles.riparazioneListItem}>
            <Pressable onPress={() => {
                onPress(item);
            }}>
                <View>
                    <View style={Theme[context.webappType].styles.itemListRow}>
                        <Text style={[Theme[context.webappType].styles.itemListLineTexts,Theme[context.webappType].styles.riparazioneCod, {fontSize: Theme[context.webappType].Sizes.tiny}]}>N° Busta {itemId}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.itemListRow}>
                        <Text style={[Theme[context.webappType].styles.itemListLineTexts,Theme[context.webappType].styles.riparazioneCod]}>{item.product.name}</Text>
                        <Text style={[Theme[context.webappType].styles.itemListLineTexts,Theme[context.webappType].styles.riparazioneDate]}>{item.date}</Text>
                        <Text style={[Theme[context.webappType].styles.itemListLineTexts,Theme[context.webappType].styles.riparazioneReady, {color: itemReadyTextColor}]}>{item.stato}</Text>
                    </View>

                    <View style={Theme[context.webappType].styles.itemListRow}>
                        <Text style={[Theme[context.webappType].styles.itemListLineTexts, Theme[context.webappType].styles.titleFillRight]}>{item.customer.name} {item.customer.surname}</Text>
                        <Text style={[Theme[context.webappType].styles.itemListLineTexts, Theme[context.webappType].styles.riparazionePrice]}>€ {priceToView}</Text>
                    </View>
                    {
                        item.utente.name &&
                        <View style={Theme[context.webappType].styles.itemListRow}>
                            <Text style={[Theme[context.webappType].styles.itemListLineTexts]}>Utente: {item.utente.name}</Text>
                        </View>
                    }
                    {
                        item.tecnico.name &&
                        <View style={Theme[context.webappType].styles.itemListRow}>
                            <Text style={[Theme[context.webappType].styles.itemListLineTexts]}>Tecnico: {item.tecnico.name}</Text>
                        </View>
                    }
                    <View style={Theme[context.webappType].styles.itemListRow}>
                        <Text style={[Theme[context.webappType].styles.itemListLineTexts]}>Magazzino: {item.magazzino.name}</Text>
                        <View style={Theme[context.webappType].styles.itemListFillSpace}/>
                        {/* <GenericButton text="Foto (0)" icon="camera-outline" size="medium" bgColor={Theme[context.webappType].Colors.info} onClick={() => { console.log('click'); }}></GenericButton> */}
                    </View>
                </View>
            </Pressable>
        </View>
    );
}

export default RiparazioneListItem;
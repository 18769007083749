import React, { useContext , useEffect , useState } from 'react';
import { SafeAreaView , View , Text , FlatList, Pressable } from 'react-native';
import { Theme } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';
import RiparazioneListItem from '../components/RiparazioneListItem';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import Pagination from '../components/Pagination';
import BarReader from '../components/BarReader';
import Alert from '../components/Alert';
import AsyncStorage from '@react-native-async-storage/async-storage';


function Riparazioni({navigation}) {

    const context = useContext(WinWorkGoldContext);
    const [toUpdate,setToUpdate] = useState(0);
    const [totaleRiparazioni,setTotaleRiparazioni] = useState(0);
    const [page,setPage] = useState(1);
    const [totPage,setTotPage] = useState(1);
    const [filter,setFilter] = useState('');

    const [modalScanBarCodeRequested,setModalScanBarCodeRequested] = useState(false);
    const [modalScanBarCodeShow,setModalScanBarCodeShow] = useState(false);
    const [barcodeScanned,setBarcodeScanned] = useState('');
    const [alertQrNotFoundShow,setAlertQrNotFoundShow] = useState(false);

    const changePage = (page) => {
        setPage(page);
    }

    const getDatas = async () => {

        const login = await AsyncStorage.getItem('@log_store');

        try {
            const response = await fetch(context.serverUrl + '?azione=riparazioni&pagina=' + page + '&ricerca=' + filter + '&loginp=' + login);
            const json = await response.json();

            context.riparazioni.set(json.riparazioni);
            setTotPage(json.totalePagine);
            setTotaleRiparazioni(json.totale);
        } catch (error) {
            console.log(error);
        }
    }

    const setUpdated = () => {
        setToUpdate(toUpdate + 1);
    }

    useEffect(() => {
        getDatas();
    },[filter,page,toUpdate]);

    const goToDetail = (item) => {
        navigation.navigate('RiparazioneDetail', {item, setUpdated});
    }

    const onChangeText = (searched) => {
        setPage(1);
        setFilter(searched);
    }

    const handleBarCodeScanned = async (result) => {

        const login = await AsyncStorage.getItem('@log_store');

        const response = await fetch(context.serverUrl + '?azione=scan_riparazioni&data=' + result + '&loginp=' + login);
        const json = await response.json();

        if(json.success) {
            goToDetail(json.found);
        } else {
            setBarcodeScanned(result);
            setAlertQrNotFoundShow(true);
        }

        setModalScanBarCodeShow(false);
    };

    return(
        <SafeAreaView style={Theme[context.webappType].styles.safeArea}>
            
            <View style={Theme[context.webappType].styles.view}>
                
                <PageHeader goBack={() => navigation.goBack()} title="Riparazioni" ></PageHeader>
                <SearchBar handleSearch={onChangeText} placeholder="Ricerca n° Busta o Nominativo"></SearchBar>
                {
                    context.wBarcode.rip &&
                    <View style={{ display: "flex", justifyContent: "center", alignItems:"center", borderBottomWidth: 1, borderBottomColor: Theme[context.webappType].Colors.gray, paddingTop: 5, paddingBottom: 10, paddingHorizontal: 10 }}>
                        <Pressable style={[Theme[context.webappType].styles.genericButton,{width: '100%', color: Theme[context.webappType].Colors.black,backgroundColor: Theme[context.webappType].Colors.primary}]} onPress={() => {setModalScanBarCodeRequested(true)}}>
                            <View style={{width:"100%",display: "flex",flexDirection: "row", justifyContent: "center", alignItems:"center"}}>
                                <Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold, marginRight: 10}]}>
                                    Scannerizza codice a barre
                                </Text>
                                <Icon name="barcode-outline" style={{fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.white}} />
                            </View>
                        </Pressable>
                    </View>
                }

                <View style={Theme[context.webappType].styles.pageInfo}>
                    <Text style={Theme[context.webappType].styles.pageInfoText}>
                        N° {totaleRiparazioni}
                    </Text>
                    <Pagination page={page} totPage={totPage} changePage={changePage}></Pagination>
                </View>

                {
                    context.riparazioni.get.length > 0 &&
                    <FlatList
                        data={context.riparazioni.get}
                        renderItem={({item}) => <RiparazioneListItem item={item} onPress={goToDetail}></RiparazioneListItem>}
                        keyExtractor={item => item.id}
                    />
                }
                {
                    context.riparazioni.get.length == 0 &&
                    <Text style={Theme[context.webappType].styles.emptyItem}>
                        Nessun risultato trovato
                    </Text>
                }
            </View>
            <BarReader
                modalScanBarCodeRequested={modalScanBarCodeRequested}
                setModalScanBarCodeRequested={setModalScanBarCodeRequested}
                modalScanBarCodeShow={modalScanBarCodeShow}
                setModalScanBarCodeShow={setModalScanBarCodeShow}
                afterBarCodeScanned={handleBarCodeScanned}
            ></BarReader>
            <Alert visibleTime={0} modalVisible={alertQrNotFoundShow} handleClose={() => {setAlertQrNotFoundShow(false);}} overlayColor={Theme[context.webappType].Colors.white + 'D'} content=
                {
                    <View style={{maxWidth: '80%'}}>
                        <Icon name="warning-outline" style={{fontSize: Theme[context.webappType].Sizes.extraLarge, color: Theme[context.webappType].Colors.danger}} />
                        <Text style={{color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium}}>
                            Nessuna busta trovata
                        </Text>
                        <Text style={{color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium}}>
                            Codice letto:
                        </Text>
                        <Text style={{color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium, fontWeight: Theme[context.webappType].Weights.bold}}>
                            {barcodeScanned}
                        </Text>
                    </View>
                }
            ></Alert> 
        </SafeAreaView>
    );
}

export default Riparazioni;
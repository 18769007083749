import React, { useContext, useState } from 'react';
import { SafeAreaView , View , Text , Pressable , Image , ImageBackground, FlatList } from 'react-native';
import { Theme } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';

import {WinWorkGoldContext} from '../context/WinWorkGoldContext';
import bgImage from '../assets/home-bg.jpg';
import bgImageShop from '../assets/home-bg-shop-mobile.jpg';
import bgImageShopApp from '../assets/home-bg-shop_app.jpg';

function Home({navigation}) {

    const context = useContext(WinWorkGoldContext);
    const [visUserMenu,setVisUserMenu] = useState('none');

    const logOut = () => {
        context.login.logout();
        navigation.navigate('Login');
    }

    return(
        <SafeAreaView style={Theme[context.webappType].styles.safeArea}>
            <ImageBackground source={context.webappType == 'shop' ? context.isApp ? bgImageShopApp : bgImageShop : bgImage} resizeMode={context.webappType == 'shop' ? context.isApp ? 'cover' : 'contain' : 'cover'} style={[Theme[context.webappType].styles.bgImage, {alignItems: 'flex-end'}]}>
                <View style={Theme[context.webappType].styles.menuView}>
                    {
                        <FlatList
                        data={context.menu.get.filter(screen => (!screen.webappTypesExc || (!screen.webappTypesExc.includes(context.webappType))) && (!screen.userType || screen.userType.includes(context.user.type)))}
                        renderItem={({item, index}) => {

                            var screen = item;
                            var render_counter = screen.counter && context[screen.counter].counter > 0;

                            return(
                                <Pressable key={`menu-btn-${index}`} style={Theme[context.webappType].styles.menuButton} onPress={() =>
                                    navigation.navigate(`${screen.title}`, { name: `${screen.title}`})
                                }>
                                    <Image style={Theme[context.webappType].styles.menuButtonImg} source={screen.image}/>
                                    <Text style={Theme[context.webappType].styles.menuButtonText}>{screen.title}</Text>
                                    {
                                        render_counter &&
                                        <View style={Theme[context.webappType].styles.menuCounterContainer}>
                                            <Text style={Theme[context.webappType].styles.menuCounter}>{context[screen.counter].counter}</Text>
                                        </View>
                                    }
                                </Pressable>
                                );
                        }}
                        />
                    }
                </View>

                <Pressable style={Theme[context.webappType].styles.topbarLogout} onPress={() => { setVisUserMenu(visUserMenu == 'block' ? 'none' : 'block') }}>
                    <Text style={{fontSize: Theme[context.webappType].Sizes.small, fontWeight: Theme[context.webappType].Weights.bold,color: Theme[context.webappType].Colors.white}}>Winwork</Text>
                    <Icon name="person-circle-outline" style={{fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.white}}/>
                </Pressable>

                <View style={[Theme[context.webappType].styles.userMenuBlock, Theme[context.webappType].styles.elevation, { display: visUserMenu }]}>
                    <View style={Theme[context.webappType].styles.userMenuBlockButton}>
                        <Icon name="person-circle-outline" style={{fontSize: Theme[context.webappType].Sizes.extraLarge, color: Theme[context.webappType].Colors.white}}/>
                        <Text style={{fontSize: Theme[context.webappType].Sizes.small, fontWeight: Theme[context.webappType].Weights.bold,color: Theme[context.webappType].Colors.white, marginLeft: 10}}>{context.user.get}</Text>
                    </View>
                    <Pressable style={Theme[context.webappType].styles.userMenuBlockButton} onPress={logOut}>
                        <Icon name="exit-outline" style={{fontSize: Theme[context.webappType].Sizes.extraLarge, color: Theme[context.webappType].Colors.white}}/>
                        <Text style={{fontSize: Theme[context.webappType].Sizes.small, fontWeight: Theme[context.webappType].Weights.bold,color: Theme[context.webappType].Colors.white, marginLeft: 10}}>Esci</Text>
                    </Pressable>
                </View>
            </ImageBackground>
        </SafeAreaView>
    );
}

export default Home;
import DateTimePicker from 'react-native-ui-datepicker';
import 'dayjs/locale/it';
import dayjs from 'dayjs';
import { Pressable, TextInput, View } from 'react-native';
import { useContext, useState } from 'react';
import Icon from 'react-native-vector-icons/Ionicons';

import ModalCentered from '../components/Modal';
import { Theme } from '../theme/Theme';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

export default function DatePickerPersonal({ style, value, onChange }) {

    const context = useContext(WinWorkGoldContext);

    const usDate = () => {
        if(/^(0[1-9]|[1-2][0-9]|3[0-1])[\/](0?[1-9]|1[0-2])[\/][0-9]{4}$/.test(value)) {
            value = value.split('/');
            value = value[2] + '-' + value[1] + '-' + value[0];
        } else {
            return dayjs();
        }

        return value;
    }

    const [viewDatePicker, setViewDatePicker] = useState(false);

    return (
        <>
            <View style={[{padding: 8,marginRight: 10, borderRadius: 5, fontSize: Theme[context.webappType].Sizes.medium, borderColor: Theme[context.webappType].Colors.lightGray, borderWidth: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between',alignContent: 'center', height: 'auto' },style]}>
                <TextInput style={{ borderWidth: 0, height: 'auto', outline: 'none', width: '100%' }} value={value} onChangeText={onChange}/>
                <Pressable onPress={() => setViewDatePicker(true) }>
                    <Icon name="calendar-outline" style={{fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.black}} />
                </Pressable>
            </View>

            <ModalCentered handleClose={() => {setViewDatePicker(false)}} title="Seleziona la data" modalVisible={viewDatePicker} content=
                {
                    <DateTimePicker
                    mode='date'
                    locale='it'
                    firstDayOfWeek={1}
                    value={usDate()}
                    onValueChange={(date) => { console.log(date); onChange(date.split(' ')[0]); setViewDatePicker(false); }}
                    />
                }
            ></ModalCentered>
        </>
    )
}
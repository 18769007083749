import React, { useContext , useEffect , useState } from 'react';
import { SafeAreaView , View , Text , FlatList } from 'react-native';
import { Theme } from '../theme/Theme';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';
import ClienteListItem from '../components/ClienteListItem';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import Pagination from '../components/Pagination';
import AsyncStorage from '@react-native-async-storage/async-storage';

function Clienti({navigation}) {

    const context = useContext(WinWorkGoldContext);
    const [totale,setTotale] = useState(0);
    const [page,setPage] = useState(1);
    const [totPage,setTotPage] = useState(1);
    const [filter,setFilter] = useState('');

    const changePage = (page) => {
        setPage(page);
    }

    const getDatas = async () => {

        const login = await AsyncStorage.getItem('@log_store');

        try {
            const response = await fetch(context.serverUrl + '?azione=clienti&pagina=' + page + '&ricerca=' + filter + '&loginp=' + login);
            const json = await response.json();

            console.log(json);

            context.clienti.set(json.clienti);
            setTotPage(json.totalePagine);
            setTotale(json.totale);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDatas();
    },[filter,page]);

    const goToDetail = (item) => {
        navigation.navigate('ClienteDetail', {item});
    }

    const onChangeText = (searched) => {
        setPage(1);
        setFilter(searched);
    }

    return(
        <SafeAreaView style={Theme[context.webappType].styles.safeArea}>
            <View style={Theme[context.webappType].styles.view}>
                <PageHeader goBack={() => navigation.goBack()} title="Clienti" ></PageHeader>
                <SearchBar handleSearch={onChangeText} placeholder="Cerca cliente..."></SearchBar>
                
                <View style={Theme[context.webappType].styles.pageInfo}>
                    <Text style={Theme[context.webappType].styles.pageInfoText}>N° {totale}</Text>
                    <Pagination page={page} totPage={totPage} changePage={changePage}></Pagination>
                </View>

                {
                    context.clienti.get.length > 0 &&
                    <FlatList
                        data={context.clienti.get}
                        renderItem={({item}) => <ClienteListItem item={item} onPress={goToDetail}></ClienteListItem>}
                        keyExtractor={item => item.id}
                    />
                }
                {
                    context.clienti.get.length == 0 &&
                    <Text style={Theme[context.webappType].styles.emptyItem}>Nessun risultato trovato</Text>
                }
            </View>
        </SafeAreaView>
    );
}

export default Clienti;
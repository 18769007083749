import React, { useContext } from 'react';
import { Text, Pressable, View, TextInput } from 'react-native';
import { Theme } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function PickingListItem({item, handleDelete, handleEdit}) {

    const context = useContext(WinWorkGoldContext);

    return (
        <View style={{flex:1,padding: 10, borderBottomColor: Theme[context.webappType].Colors.gray, borderBottomWidth: 1}}>
            <View style={{flex: 1, flexDirection: 'row',alignItems: 'center',justifyContent: 'center'}}>
                <View style={{flex:1}}>
                    <View style={{display: 'flex', flexDirection: 'column'}}>
                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                            <View style={{flex:3}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Cod.Art.</Text>
                            </View>
                            <View style={{flex:2}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Lotto</Text>
                            </View>
                            <View style={{flex:2,paddingHorizontal: 2}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Scad.</Text>
                            </View>
                            <View style={{flex:2, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Qta.</Text>
                            </View>
                        </View>

                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 2, alignItems: 'center'}}>
                            <View style={{flex:3}}>
                                <Text>{item.codart}</Text>
                            </View>
                            <View style={{flex:2, flexWrap: 'wrap'}}>
                                <Text>{item.lotto}</Text>
                            </View>
                            <View style={{flex:2, flexWrap: 'wrap'}}>
                                <Text>{item.scad}</Text>
                            </View>

                            <View style={{flex:2, flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
                                <TextInput style={{padding: 8,marginHorizontal: 5,width: 60, borderRadius: 5, borderColor: Theme[context.webappType].Colors.lightGray, borderWidth: 1, textAlign: 'center' }} value={item.qta.toString()} onChangeText={handleEdit}/>
                            </View>
                        </View>

                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 2, alignItems: 'center'}}>
                            <View style={{flex:3}}>
                                <Text>{item.desart}</Text>
                            </View>

                            <View style={{flexShrink: 1}}>
                                <Pressable onPress={handleDelete} style={{paddingHorizontal: 5, display: 'flex', justifyContent: 'flex-end'}}>
                                    <Icon name="trash-sharp" style={{fontSize: Theme[context.webappType].Sizes.medium, color: Theme[context.webappType].Colors.black}} />
                                </Pressable>
                                {/* <Pressable onPress={handleEdit} style={{ paddingHorizontal: 10 }}>
                                    <Icon name="pencil-sharp" style={{fontSize: Theme[context.webappType].Sizes.medium, color: Theme[context.webappType].Colors.black}} />
                                </Pressable> */}
                            </View>
                        </View>

                    </View>
                </View>
            </View>
        </View>
    );
}

export default PickingListItem;
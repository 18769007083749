import React, { useContext } from 'react';
import { SafeAreaView , View , Text , Image , ScrollView } from 'react-native';
import PageHeader from '../components/PageHeader';
import { Theme } from '../theme/Theme';
import GalleryWPreviews from '../components/GalleryWPreviews';
import CurFormatter from '../components/CurFormatter';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function ProdottoDetail({route , navigation}) {


    //lastBought
    const context = useContext(WinWorkGoldContext);
    const item = route.params.item;
    const itemImages = item.img.map((img,index) => (
        { source: { uri: context.justServerUrl + img } }
    ));
    const priceFormatted = CurFormatter(item.price);
    const price2Formatted = CurFormatter(item.price2);
    const priceDiscFormatted = CurFormatter(item.priceDisc);

    return(
        <SafeAreaView style={Theme[context.webappType].styles.safeArea}>
            <View style={Theme[context.webappType].styles.view}>
                <PageHeader goBack={() => navigation.goBack()} title={`${item.title}`} ></PageHeader>
                <ScrollView style={{flexGrow:1,flex:1}}>
                    {
                        item.img.length > 0 &&
                        <GalleryWPreviews
                        images={itemImages}/>
                    }
                    {
                        item.img.length == 0 &&
                        <View style={[{flex:1}]}>
                            <Image style={Theme[context.webappType].styles.prodottoImg}  source={{ uri: context.placeholderUrl }}></Image>
                        </View>
                    }
                    <View style={Theme[context.webappType].styles.blockTitle}>
                        <Text style={Theme[context.webappType].styles.blockTitleText}>Prodotto {item.id}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Descrizione articolo</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.desc}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Descrizione breve</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.descBreve}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Titolo</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.title}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Descrizione estesa</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.descEstesa}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Tipo</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.tipo}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Categoria</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.categoria}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Modello</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.modello}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Fornitore</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.fornitore}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Marchio</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.marchio}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Ref. Fornitore</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.refFornitore}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Unità di Misura</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.UM}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Misura</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.misura}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>IVA</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.IVA}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Rep. Fiscale</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.repFiscale}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Giacenza</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.giacenza}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Ultima data di acquisto</Text>
                        <Text style={Theme[context.webappType].styles.singleBlockText}>{item.lastBought}</Text>
                    </View>
                    <View style={Theme[context.webappType].styles.singleBlock}>
                        <Text style={Theme[context.webappType].styles.singleBlockTitle}>Prezzo di vendita</Text>
                        {
                            item.priceDisc > 0 && item.priceDisc < item.price &&
                            <View style={Theme[context.webappType].styles.singleBlockText}>
                                <Text style={Theme[context.webappType].styles.prodottoOldPrice}> {priceFormatted}</Text>
                                <Text style={Theme[context.webappType].styles.prodottoPrice}> {priceDiscFormatted}</Text>
                            </View>
                        }
                        {
                            item.priceDisc == 0 &&
                            <View style={Theme[context.webappType].styles.singleBlockText}>
                                <Text style={Theme[context.webappType].styles.prodottoPrice}> {priceFormatted}</Text>
                            </View>
                        }
                    </View>
                    {
                        (item.price2 > 0) &&
                        <View style={Theme[context.webappType].styles.singleBlock}>
                            <Text style={Theme[context.webappType].styles.singleBlockTitle}>Prezzo di acquisto</Text>
                            <View style={Theme[context.webappType].styles.singleBlockText}>
                                <Text style={Theme[context.webappType].styles.prodottoPrice}> {price2Formatted}</Text>
                            </View>
                        </View>
                    }
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}

export default ProdottoDetail;
import React, { useContext } from 'react';
import { Pressable, Text, View } from 'react-native';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';
import { Theme } from '../theme/Theme';

function MagazzinoListItem({item,onPress}) {

    const context = useContext(WinWorkGoldContext);

    return (
        <View style={Theme[context.webappType].styles.generalListItem}>
            <Pressable onPress={() => {
                onPress(item);
            }}>
                <View style={Theme[context.webappType].styles.itemListRow}>
                    <View style={[Theme[context.webappType].styles.itemListFillSpace, { display: 'flex', flexShrink: 1 }]}>
                        <View style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                            {
                                item.descr &&
                                <Text style={[Theme[context.webappType].styles.itemListLineTexts, Theme[context.webappType].styles.prodottoDesc]}>{item.descr}</Text>
                            }
                        </View>
                    </View>
                </View>
            </Pressable>
        </View>
    );
}

export default MagazzinoListItem;
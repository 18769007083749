import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView , View , Text, Pressable, FlatList, TextInput } from 'react-native';
import { Theme } from '../theme/Theme';
import {WinWorkGoldContext} from '../context/WinWorkGoldContext';
import PageHeader from '../components/PageHeader';
import Icon from 'react-native-vector-icons/Ionicons';

import ModalCentered from '../components/Modal';
import MagazzinoListItem from '../components/MagazzinoListItem';
import Alert from '../components/Alert';

import BarReader from '../components/BarReader';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MovimentiListItem from '../components/MovimentiListItem';

/*
Bar code format	    iOS	    Android
aztec	            Yes	    Yes
codabar	            Yes	    Yes
code39	            Yes	    Yes
code93	            Yes	    Yes
code128	            Yes	    Yes
code39mod43	        Yes	    No
datamatrix	        Yes 	Yes
ean13	            Yes	    Yes
ean8	            Yes	    Yes
interleaved2of5	    Yes	    use itf14
itf14	            Yes*	Yes
maxicode	        No	    Yes
pdf417	            Yes	    Yes
rss14	            No	    Yes
rssexpanded	        No	    Yes
upc_a	            No	    Yes
upc_e	            Yes	    Yes
upc_ean	            No	    Yes
qr	                Yes	    Yes

PER FILTRARLI , su camera aggiungi attributo:
barCodeScannerSettings={{
                    barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                }}
*/

function Inventario({navigation}) {

    const context = useContext(WinWorkGoldContext);

    const [modalMagSelectionShow,setModalMagSelectionShow] = useState(true);
    const [magazzinoChanged,setMagazzinoChanged] = useState(false);
	const [activeMagazzino, setActiveMagazzino] = useState({ id: 0, descr: '' });

    const [magQta,setMagQta] = useState(1);
    const [manualBarcode,setManualBarcode] = useState('');

    const [alertProductNotFoundShow,setAlertProductNotFoundShow] = useState(false);

    const [modalScanBarCodeRequested,setModalScanBarCodeRequested] = useState(false);
    const [modalScanBarCodeShow,setModalScanBarCodeShow] = useState(false);
    const [barcodeScanned,setBarcodeScanned] = useState('');

    const [movimenti, setMovimenti] = useState([]);
    
    const modals = {
        'magSelection' : setModalMagSelectionShow
    }

    const getMovimenti = async () => {

        const login = await AsyncStorage.getItem('@log_store');
        
        try {
            const response = await fetch(context.serverUrl + '?azione=movimenti&mag=' + activeMagazzino.id + '&utente=' + context.user.get + '&loginp=' + login);
            const json = await response.json();


            setMovimenti(json.movimenti);
        } catch (error) {
            console.log(error);
        }
    }

    const openModal = (key) => {
        modals[key](true);
    };
    const closeModal = (key) => {
        modals[key](false);
    };

    const sendBarCode = async (result) => {

        const login = await AsyncStorage.getItem('@log_store');
        setManualBarcode('');

        if(context.inventarioNoCheckBarcodeErr.get) {
            await addToInventory(result, { 'codart': '','desart': '' });
        } else {
            try {
                const response = await fetch(context.serverUrl + '?azione=scan_prodotti_inventario&data=' + result + '&loginp=' + login);
                const json = await response.json();

                if(json.success) {
                    await addToInventory(result, {...json.foundProduct});
                } else {
                    setBarcodeScanned(result);
                    setAlertProductNotFoundShow(true);
                }
            } catch (error) {
                console.log(error);
            }
        }

        setMagQta(1);
    }

    const handleBarCodeScanned = async (result) => {

        await sendBarCode(result);

        setMagQta(1);

        setModalScanBarCodeShow(false);
        /* setTimeout(() => {
            setModalScanBarCodeRequested(true);
        }, 1000); */
    };

    const addToInventory = async (tmpbarcodeScanned, product) => {
        const login = await AsyncStorage.getItem('@log_store');

        let tmpMagQta = magQta;
        if(magQta == 0) {
            setMagQta(1);
            tmpMagQta = 1;
        }

        let data = new Date();
        let ora_t = data.getHours(); 
        ora_t = ora_t < 10 ? '0' + ora_t : ora_t;
        let min_t = data.getMinutes(); 
        min_t = min_t < 10 ? '0' + min_t : min_t;
        let ora  = ora_t + ':' + min_t;
        let day  = data.getDate();
        day = day < 10 ? '0' + day : day;
        let mese  = data.getMonth() + 1;
        mese = mese < 10 ? '0' + mese : mese;
        let data_string  = day + '/' + mese + '/' + data.getFullYear();

        let movimento = { 
            'qta': tmpMagQta,
            'codart': product.codart,
            'desart': product.desart,
            'barcode': tmpbarcodeScanned,
            'data': data_string,
            'lotto': '',
            'scad': '',
            ora
        };

        const movimento_string = encodeURIComponent(JSON.stringify(movimento));

        try {
            const response = await fetch(context.serverUrl + '?azione=save_movimento&movimento=' + movimento_string + '&mag=' + activeMagazzino.id + '&utente=' + context.user.get + '&loginp=' + login);

            const json = await response.json();

            if(json.success) {
                movimento.id = json.movimento.id;
                let newMovimenti = [];
                newMovimenti.push(movimento);
                newMovimenti.push(...movimenti);
                setMovimenti(newMovimenti);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const editInventory = async (item, newValue, prop = 'qta') => {

        let loadOnline = true;
        if(prop == 'scad' && !/^(0[1-9]|[1-2][0-9]|3[0-1])[\/](0?[1-9]|1[0-2])[\/][0-9]{4}$/.test(newValue) && !/^[0-9]{4}[-](0[1-9]|1[0-2])[-](0[1-9]|[1-2][0-9]|3[0-1])$/.test(newValue)) {

            if((/^(0[1-9]|[1-2][0-9]|3[0-1])$/.test(newValue) || /^(0[1-9]|[1-2][0-9]|3[0-1])[\/](0[1-9]|1[0-2])$/.test(newValue))) {
                if(!/^(0[1-9]|[1-2][0-9]|3[0-1])[\/]$/.test(item[prop]) && !/^(0[1-9]|[1-2][0-9]|3[0-1])[\/](0[1-9]|1[0-2])[\/]$/.test(item[prop])) {
                    newValue += '/';
                } else {
                    newValue = newValue.slice(0,-1);
                }
            }

            loadOnline = false;
        }

        let newQtaInt;
        if(prop == 'qta') {
            newQtaInt = parseInt(newValue);
        }
        const editMov = {...item};
        editMov[prop] = newValue;

        if(prop == 'qta' && newValue === '') {
            loadOnline = false;
        }

        if(prop != 'qta' || (newQtaInt > 0 || newValue === '')) {

            let newMovimenti = [];
            let totMovimenti = movimenti.length;

            for(let i = 0; i < totMovimenti; i++) {
                let tmpMovimento = movimenti[i];

                if(tmpMovimento.id != item.id) {
                    newMovimenti.push(tmpMovimento);
                } else {
                    newMovimenti.push(editMov);
                }
            }

            setMovimenti(newMovimenti);
        } else if(loadOnline) {
            setMovimenti([...(movimenti.filter(mov => mov.id != item.id))]);
        }

        if(loadOnline) {
            const login = await AsyncStorage.getItem('@log_store');

            const movimento_string = encodeURIComponent(JSON.stringify({...editMov, prop: prop}));
    
            try {
                const response = await fetch(context.serverUrl + '?azione=save_movimento&movimento=' + movimento_string + '&loginp=' + login);
    
                const json = await response.json();

                if(prop == 'scad') {

                    if(!editMov.scadError) {
                        editMov.scadError = json.success;
                    }

                    if(editMov.scadError != !json.success) {
                        editMov.scadError = !json.success;

                        let newMovimenti = [];
                        let totMovimenti = movimenti.length;
    
                        for(let i = 0; i < totMovimenti; i++) {
                            let tmpMovimento = movimenti[i];
    
                            if(tmpMovimento.id != item.id) {
                                newMovimenti.push(tmpMovimento);
                            } else {
                                newMovimenti.push(editMov);
                            }
                        }
    
                        setMovimenti(newMovimenti);
                    }
                }

            } catch (error) {
                console.log(error);
            }
        }

    }

    const selectMag = (mag) => {
        setActiveMagazzino(mag);
        setMagazzinoChanged(true);
        closeModal('magSelection');
    }

    const changeQta = (newQta) => {
        const newQtaInt = (newQta != '') ? parseInt(newQta) : '';

        if(newQta === '' || (newQtaInt && newQtaInt > 0)) {
            setMagQta(newQtaInt);
        }
    }

    useEffect(() => {
        if(!activeMagazzino.id) {
            openModal('magSelection');
        } else if(magazzinoChanged) {
            setMagazzinoChanged(false);
            getMovimenti();
        }
    },[magazzinoChanged]);

    return(
        <SafeAreaView style={Theme[context.webappType].styles.safeArea}>
            <View style={Theme[context.webappType].styles.view}>
                <PageHeader goBack={() => navigation.goBack()} title={ "Inventario - " + context.user.get }></PageHeader>
                <View style={{display: 'flex', flexDirection: 'row',justifyContent: 'between', alignItems: 'center', paddingVertical: 15, paddingHorizontal: 25 }}>
                    <View style={{ flexShrink: 1 }}>
                        <Text>Magazzino selezionato:</Text>
                        { 
                            activeMagazzino.id ? 
                            <Text style={{ fontWeight: Theme[context.webappType].Weights.bold, fontSize: Theme[context.webappType].Sizes.medium }}>{ activeMagazzino.descr }</Text>
                            :
                            <Text>magazzino non selezionato</Text>
                        }
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexGrow: 1 }}>
                        <Pressable style={[Theme[context.webappType].styles.genericButton,{ color: Theme[context.webappType].Colors.black,backgroundColor: Theme[context.webappType].Colors.info}]} onPress={() => {openModal('magSelection')}}>
                            <View style={{display: "flex",flexDirection: "row", justifyContent: "center", alignItems:"center"}}><Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold, marginRight: 10}]}>Cambia</Text><Icon name="refresh-outline" style={{fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.white}} /></View>
                        </Pressable>
                    </View>
                </View>
                {<View style={{display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center', padding: 10}}>
                    <Text style={{ fontSize: Theme[context.webappType].Sizes.large }}>Qta.</Text>
                    <TextInput style={{padding: 8,marginHorizontal: 5,width: 100, borderRadius: 5, fontSize: Theme[context.webappType].Sizes.large, borderColor: Theme[context.webappType].Colors.lightGray, borderWidth: 1, textAlign: 'center' }} value={magQta.toString()} onChangeText={changeQta}/>
                </View>}
                <View style={{ display: "flex", justifyContent: "center", alignItems:"center", paddingTop: 5, paddingBottom: 10, paddingHorizontal: 10 }}>
                    <Pressable style={[Theme[context.webappType].styles.genericButton,{width: '100%', color: Theme[context.webappType].Colors.black,backgroundColor: Theme[context.webappType].Colors.primary}]} onPress={() => {setModalScanBarCodeRequested(true)}}>
                        <View style={{width:"100%",display: "flex",flexDirection: "row", justifyContent: "center", alignItems:"center"}}><Text style={[Theme[context.webappType].styles.textWhite, { fontWeight: Theme[context.webappType].Weights.bold, marginRight: 10}]}>Scannerizza codice a barre</Text><Icon name="barcode-outline" style={{fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.white}} /></View>
                    </Pressable>
                </View>
                <Text style={{ fontSize: Theme[context.webappType].Sizes.tiny, textAlign: 'center' }}>Oppure inseriscilo a mano</Text>
                <View style={{ display: "flex", flexDirection: 'row', justifyContent: "center", alignItems:"center", borderBottomWidth: 1, borderBottomColor: Theme[context.webappType].Colors.gray, paddingTop: 5, paddingBottom: 10, paddingHorizontal: 10 }}>
                    <TextInput style={{padding: 8,marginHorizontal: 5,width: 100, borderRadius: 5, fontSize: Theme[context.webappType].Sizes.large, borderColor: Theme[context.webappType].Colors.lightGray, borderWidth: 1, textAlign: 'center', flexGrow: 1 }} value={manualBarcode} onChangeText={setManualBarcode}/>
                    <Pressable style={[Theme[context.webappType].styles.genericButton,{color: Theme[context.webappType].Colors.white,backgroundColor: Theme[context.webappType].Colors.success}]} onPress={() => {if(manualBarcode != '') { sendBarCode(manualBarcode); }}}>
                        <Icon name="checkmark-outline" style={{fontSize: Theme[context.webappType].Sizes.large, color: Theme[context.webappType].Colors.white}} />
                    </Pressable>
                </View>

                {
                    movimenti && movimenti.length > 0 &&
                    <FlatList
                        data={movimenti}
                        renderItem={({item}) => <MovimentiListItem item={item} handleDelete={() => {editInventory(item, 0)}} handleEditQta={(newQta) => { let newQtaInt = parseInt(newQta); if(newQtaInt > 0 || newQta === '') editInventory(item, newQta)}} handleEditLotto={(newLotto) => { editInventory(item, newLotto, 'lotto')}} handleEditScad={(newScad) => { editInventory(item, newScad, 'scad')}}></MovimentiListItem>}
                        keyExtractor={item => item.id}
                    />
                }
                {
                    (!movimenti || movimenti.length == 0) &&
                    <Text style={Theme[context.webappType].styles.emptyItem}>Nessun movimento presente</Text>
                }
            </View>
            <ModalCentered handleClose={() => {closeModal('magSelection')}} title="Seleziona Magazzino" modalVisible={modalMagSelectionShow} content=
            {
                <>
                    {
                        (context.magazzini.get && context.magazzini.get.length > 0) ?
                        (<FlatList
                            data={context.magazzini.get}
                            renderItem={({item}) => <MagazzinoListItem item={item} onPress={() => {selectMag(item)}}></MagazzinoListItem>}
                            keyExtractor={item => item.id}
                        />)
                        :
                        (<Text style={Theme[context.webappType].styles.emptyItem}>Nessun risultato trovato</Text>) 
                    }
                </>
            }
            ></ModalCentered>
            <BarReader
                modalScanBarCodeRequested={modalScanBarCodeRequested}
                setModalScanBarCodeRequested={setModalScanBarCodeRequested}
                modalScanBarCodeShow={modalScanBarCodeShow}
                setModalScanBarCodeShow={setModalScanBarCodeShow}
                afterBarCodeScanned={handleBarCodeScanned}
            ></BarReader>
            <Alert visibleTime={0} modalVisible={alertProductNotFoundShow} handleClose={() => {setAlertProductNotFoundShow(false);}} overlayColor={Theme[context.webappType].Colors.white + 'D'} content=
            {
                <View style={{maxWidth: '80%'}}>
                    <Icon name="warning-outline" style={{fontSize: Theme[context.webappType].Sizes.extraLarge, color: Theme[context.webappType].Colors.danger}} />
                    <Text style={{color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium}}>Nessun prodotto trovato</Text>
                    <Text style={{color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium}}>Codice letto:</Text>
                    <Text style={{color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium, fontWeight: Theme[context.webappType].Weights.bold}}>{barcodeScanned}</Text>
                </View>
            }
            ></Alert> 
        </SafeAreaView>
    );
}


export default Inventario;
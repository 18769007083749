import React, { useContext } from 'react';
import { Text, Pressable, View } from 'react-native';
import { Theme } from '../theme/Theme';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function FornitoreListItem({item,onPress}) {

    const context = useContext(WinWorkGoldContext);

    return (
        <View style={Theme[context.webappType].styles.generalListItem}>
            <Pressable onPress={() => {
                onPress(item);
            }}>
                <View>
                    <View style={Theme[context.webappType].styles.itemListRow}>
                        <Text style={[Theme[context.webappType].styles.itemListLineTexts, Theme[context.webappType].styles.titleFillRight]}>{item.desc}</Text>
                        <Text style={[Theme[context.webappType].styles.itemListLineTexts]}>{item.provin}</Text>
                    </View>
                </View>
            </Pressable>
        </View>
    );
}

export default FornitoreListItem;
import React, { useContext } from 'react';
import { Text, Pressable, View , Image, TextInput} from 'react-native';
import { Theme } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import dayjs from 'dayjs';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';
import DatePickerPersonal from './DatePickerPersonal';

function MovimentiListItem({item, handleDelete, handleEditQta, handleEditLotto, handleEditScad}) {

    const context = useContext(WinWorkGoldContext);

    return (
        <View style={{flex:1,padding: 10, borderBottomColor: Theme[context.webappType].Colors.gray, borderBottomWidth: 1}}>
            <View style={{flex: 1, flexDirection: 'row',alignItems: 'center',justifyContent: 'center'}}>
                <View style={{flex:1}}>
                    <View style={{display: 'flex', flexDirection: 'column'}}>
                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                            <View style={{flex:1}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Barcode</Text>
                            </View>
                            <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Qta.</Text>
                            </View>
                            <View style={{flex:1}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Tempo</Text>
                            </View>
                        </View>

                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                            <View style={{flex:1}}>
                                <Text>{item.barcode}</Text>
                            </View>
                            <View style={{flex:1, flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
                                <TextInput style={{padding: 8,marginHorizontal: 5,width: 100, borderRadius: 5, fontSize: Theme[context.webappType].Sizes.medium, borderColor: Theme[context.webappType].Colors.lightGray, borderWidth: 1, textAlign: 'center' }} value={item.qta.toString()} onChangeText={handleEditQta}/>
                            </View>
                            <View style={{flex:1}}>
                                <Text>{item.data} {item.ora}</Text>
                            </View>
                        </View>

                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5}}>
                            <View style={{flex:1}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Lotto</Text>
                            </View>
                            <View style={{flex:1}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Scadenza</Text>
                            </View>
                        </View>

                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5}}>
                            <View style={{flex:1}}>
                                <TextInput style={{padding: 8,marginRight: 10, borderRadius: 5, fontSize: Theme[context.webappType].Sizes.medium, borderColor: Theme[context.webappType].Colors.lightGray, borderWidth: 1 }} value={item.lotto.toString()} onChangeText={handleEditLotto}/>
                            </View>
                            <View style={{flex:1}}>
                                <DatePickerPersonal style={{padding: 8,marginRight: 10, borderRadius: 5, fontSize: Theme[context.webappType].Sizes.medium }} onChange={handleEditScad} value={/^[0-9]{4}[-](0[1-9]|1[0-2])[-](0[1-9]|[1-2][0-9]|3[0-1])$/.test(item.scad) ? dayjs(item.scad).format('DD/MM/YYYY') : item.scad}></DatePickerPersonal>
                                {
                                    item.scadError &&
                                    <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,color: Theme[context.webappType].Colors.danger}}>Data non valida</Text>
                                }
                            </View>
                        </View>

                        {
                            !context.inventarioNoCheckBarcodeErr.get &&
                            <>
                                <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                                    <View style={{flex:3,paddingHorizontal: 2}}>
                                        <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Cod.Art.</Text>
                                    </View>
                                    <View style={{flex:5}}>
                                        <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Des.Art.</Text>
                                    </View>
                                    <View style={{flex:1}}>
                                    </View>
                                </View>

                                <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 5, alignItems: 'center'}}>
                                    <View style={{flex:3,paddingHorizontal: 2}}>
                                        <Text>{item.codart}</Text>
                                    </View>
                                    <View style={{flex:5}}>
                                        <Text>{item.desart}</Text>
                                    </View>
                                    <View style={{flex:1}}>
                                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 0, alignItems: 'center', justifyContent: 'flex-end'}}>
                                            <Pressable onPress={handleDelete} style={{ paddingHorizontal: 10 }}>
                                                <Icon name="trash-sharp" style={{fontSize: Theme[context.webappType].Sizes.medium, color: Theme[context.webappType].Colors.black}} />
                                            </Pressable>
                                        </View>
                                    </View>
                                </View>
                            </>
                        }
                    </View>
                </View>
            </View>
        </View>
    );
}

export default MovimentiListItem;
import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { Text, Pressable, View , Image, TextInput} from 'react-native';
import { Theme } from '../theme/Theme';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';
import DatePickerPersonal from './DatePickerPersonal';

function GiacenzaListItem({item, handleEdit, handleEditLotto = () => {}, handleEditScad = () => {}}) {

    const context = useContext(WinWorkGoldContext);

    return (
        <View style={{flex:1,padding: 10, borderBottomColor: Theme[context.webappType].Colors.gray, borderBottomWidth: 1}}>
            <View style={{flex: 1, flexDirection: 'row',alignItems: 'center',justifyContent: 'center'}}>
                <View style={{flex:1}}>
                    <View style={{display: 'flex', flexDirection: 'column'}}>
                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 2, alignItems: 'center'}}>
                            <View style={{flex:1}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Lotto</Text>
                            </View>
                            <View style={{flex:1}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Scad.</Text>
                            </View>
                            <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Qta.</Text>
                            </View>
                            {
                                !item.pers &&
                                <>
                                    <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Giac.</Text>
                                    </View>
                                    <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{fontSize:Theme[context.webappType].Sizes.tiny,fontWeight: Theme[context.webappType].Weights.bold}}>Carr.</Text>
                                    </View>
                                </>
                            }
                        </View>

                        <View style={{display: 'flex', flexDirection: 'row',paddingVertical: 2, alignItems: 'center'}}>
                            <View style={{flex:1}}>
                                {
                                    !item.pers ?
                                    <Text>{item.lotto}</Text>
                                    :
                                    <TextInput style={{padding: 8,marginHorizontal: 5, borderRadius: 5, borderColor: Theme[context.webappType].Colors.lightGray, borderWidth: 1, maxWidth: '98%' }} value={item.lotto.toString()} onChangeText={handleEditLotto}/>
                                }
                            </View>
                            <View style={{flex:1}}>
                                {
                                    !item.pers ?
                                    <Text>{item.scad}</Text>
                                    :
                                    <DatePickerPersonal style={{paddingVertical: 3, paddingHorizontal: 5,marginHorizontal: 5, borderRadius: 5, fontSize: Theme[context.webappType].Sizes.small }} onChange={handleEditScad} value={item.scad}></DatePickerPersonal>
                                }
                            </View>
                            <View style={{flex:1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
                                <TextInput style={{padding: 8,marginHorizontal: 5,width: 60, borderRadius: 5, borderColor: item.pers || item.qta <= item.giac ? Theme[context.webappType].Colors.lightGray : Theme[context.webappType].Colors.danger, borderWidth: 1 }} value={item.qta.toString()} onChangeText={handleEdit} onFocus={(e) => { if(e.target.value === '0') handleEdit(''); }}/>
                            </View>
                            {
                                !item.pers &&
                                <>
                                    <View style={{flex:1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{ color: item.qta <= item.giac ? Theme[context.webappType].Colors.black : Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.small }}>{item.giac}</Text>
                                    </View>
                                    <View style={{flex:1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{ color: Theme[context.webappType].Colors.black, fontSize: Theme[context.webappType].Sizes.small }}>{item.in_cart}</Text>
                                    </View>
                                </>
                            }
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default GiacenzaListItem;
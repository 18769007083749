import React, { useState, useEffect, useContext } from 'react';
import { Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Theme } from '../theme/Theme';
import ModalCentered from './Modal';
import Alert from './Alert';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

export default function BarReader({modalScanBarCodeShow,setModalScanBarCodeShow,modalScanBarCodeRequested,setModalScanBarCodeRequested, afterBarCodeScanned}) {

    const context = useContext(WinWorkGoldContext);

    const [alertPermissionRequiredShow,setAlertPermissionRequiredShow] = useState(false);
	const [stopStream, setStopStream] = useState(false);

	useEffect(() => {
		if(modalScanBarCodeRequested) {
			scanBarCode();
			setModalScanBarCodeRequested(false);
			setStopStream(false);
		}
	}, [context.cameraPermission.get,modalScanBarCodeRequested]);

    const askPermission = async () => {
        const { status } = await BarCodeScanner.requestPermissionsAsync();
        context.cameraPermission.set(status === 'granted');
        return status === 'granted';
    }

    const scanBarCode = async () => {
        if(!context.cameraPermission.get) {
            const status = await askPermission();
            if(!status) {
                setAlertPermissionRequiredShow(true);
                return;
            } else {
				setModalScanBarCodeShow(true);
			}
        } else {
			setModalScanBarCodeShow(true);
		}
    }

	const handleBarCodeError = async (error) => {
		scanBarCode();
	};

    const handleBarCodeScanned = (err, result) => {
		if (result) {
			setStopStream(true);
			afterBarCodeScanned(result.text);
		}
    };

	return (
		<>
			<ModalCentered maxContentHeight="90%" maxContainerHeight="100%" handleClose={() => {setModalScanBarCodeShow(false)}} title="Inquadra un barcode" modalVisible={modalScanBarCodeShow} content=
			{
				<View style={{height: 300,width: '100%'}}>
					<BarcodeScannerComponent
						style={[{flex:1}]}
						onUpdate={handleBarCodeScanned}
						onError={handleBarCodeError}
						stopStream={stopStream}
					/>
				</View>
			}
			></ModalCentered>
			<Alert visibleTime={3000} modalVisible={alertPermissionRequiredShow} handleClose={() => {setAlertPermissionRequiredShow(false);}} overlayColor={Theme[context.webappType].Colors.white + 'A'} content=
				{
					<View style={{maxWidth: '80%'}}>
						<Icon name="warning-outline" style={{fontSize: Theme[context.webappType].Sizes.extraLarge, color: Theme[context.webappType].Colors.danger}} />
						<Text style={{color: Theme[context.webappType].Colors.danger, fontSize: Theme[context.webappType].Sizes.medium}}>Per poter utilizzare questa funzione devi disporre di una telecamera e dare il permesso all'app di utilizzarla</Text>
					</View>
				}
            ></Alert> 
		</>
	);
}

import React, { useContext, useState } from 'react';
import { View , TextInput, Pressable  } from 'react-native';
import { Theme } from '../theme/Theme';
import Icon from 'react-native-vector-icons/Ionicons';
import { WinWorkGoldContext } from '../context/WinWorkGoldContext';

function SearchBar({handleSearch , placeholder, wButton = false, renderButton = '', pressableStyle = {},wIcon = true, extraInputStyle = {}}) {

    const context = useContext(WinWorkGoldContext);

    if(placeholder == '') {
        placeholder = 'Cerca...';
    }

    const [searctText, setSearchText] = useState('');
    const updateSearchText = (value) => {
        setSearchText(value);
    }

    const handleSearchAndUpdate = (value) => {
        setSearchText(value);
        handleSearch(value);
    }

    const onChangeText = wButton ? updateSearchText : handleSearchAndUpdate;

    const extraMargin = { marginRight: wButton ? 10 : 0, marginLeft: wIcon ? 10 : 0 };

    return (
        <View style={[Theme[context.webappType].styles.searchBar, extraInputStyle]}>
            {
                wIcon &&
                <Icon name="search-outline" style={Theme[context.webappType].styles.searchBarIcon} />
            }
            <View style={{flexGrow: 1,flex:1}}>
                <TextInput style={[Theme[context.webappType].styles.searchBarInput, extraMargin]} onChangeText={onChangeText} onSubmitEditing={() => {handleSearch(searctText)}} onKeyPress={({ nativeEvent: { key: keyValue } }) => { if(keyValue == 'Enter') handleSearch(searctText)}} placeholder={placeholder}/>
            </View>
            {
                wButton &&
                <Pressable style={pressableStyle} onPress={() => {handleSearch(searctText)}}>
                    {renderButton}
                </Pressable>
            }
        </View>
    );
}

export default SearchBar;